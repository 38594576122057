<template>
  <div class="foot">Foot</div>
</template>

<script>
export default {
  name: "Foot",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
</style>