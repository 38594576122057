<template>
    <!--智营客-->

    <div class="product">
        <coreNavigation></coreNavigation>
        <div class="tab">
            <a href="#part11" class="">{{ product.title }}</a>
            <a href="#part1" class="">{{ functional.title }}</a>
            <a href="#part2" class="">{{ business.title }}</a>
            <a href="#part3" class="">{{ worth.title }}</a>
        </div>
        <div class="" >

            <div class="part3 part" id="part11">
                <div class="contain">
                    <div class="title" style="padding-top:100px">
                        <p class="p1">{{product.title}}</p>
                        <p  v-html = "product.detailInfo" class="p2">
                           
                        </p>
                    </div>
                    <div class="img">
                        <!-- <img src=""> -->
                    </div>
                </div>
            </div>

            <div class="part1 part content" id="part1">
                <div class="contain ">
                    <div class="title">
                        <p class="p1">{{ functional.title }}</p>
                        <p v-html="functional.detailInfo" class="p2">                         
                        </p>
                    </div>
                    <ul class="main clear">
                        <li v-for="i in functionalData ">
                            <img width="40" height="38" :src="i.picUrl" class="attachment-40x40 size-40x40" alt="" loading="lazy">
                            <p class="p1">{{ i.title }}</p>
                            <p class="p2">{{ i.titleSubtitle }}</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="part2 part" id="part2">
                <div class="contain">
                    <div class="title">
                        <p class="p1">{{ business.title }}</p>
                    </div>
                    <div class="img">
                        <img :src="business.picUrl">
                    </div>
                </div>
            </div>

            <div class="part3 part" id="part3">
                <div class="contain">
                    <div class="title">
                        <p class="p1">{{ worth.title }}</p>
                        <p class="p2">{{ worth.titleSubtitle }}
                        </p>
                    </div>
                    <div class="img">
                         <img :src="worth.picUrl">
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import coreNavigation from '@/components/PC/CoreProduct/coreNavigation.vue';
export default {
    name: 'one',
    components: {
        coreNavigation
    },
    data() {
        return {
            newres:[],
            data:[],
            product:[],
            functional:[],
            functionalData:[],
            business:[],
            worth:[]
        }
    },
    methods: {
        
        go(url) {
            let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
            let path = url.split('?')[0]
            let paramter = url.split('=')[1]
            if (rule.test(url)) {
                window.open(url, '_blank')
                this.isDown = false;
            } else {
                this.$router.push({ path: `${path}`, query: { datekey: paramter } });
                this.isDown = false;
            }
        },
    },
    mounted() {
    this.$store.state.data.then((res) => {
      // console.log(res)
      res.data.data.map((el) => {
        //核心产品
        if (el.mouldSectionKey == '04691e5d-bac2-4ae0-b826-5c4cdc206a50') {
          this.newres = el
        }
      })
        //智赢客数据接收
        let a = this.newres.data;
        for(let i = 0;i<a.length;i++){
            if(a[i].contentKey.includes('超优荟')){
                this.data.push(a[i]);
            } 
        }
        this.product = this.data[0].childrenContent[0];
        this.functional = this.data[1].childrenContent[0];
        this.functionalData = this.data[1].childrenContent.slice(1);
        this.business = this.data[2].childrenContent[0];
        this.worth = this.data[3].childrenContent[0];
    })
  },
}
</script>
<style scoped>
.product .tab {
    background: #fbfcff;
    text-align: center;
}

.product .tab a {
    font-size: 16px;
    color: #1f2b41;
    line-height: 50px;
    font-weight: bold;
    border-bottom: 2px solid transparent;
    margin: 0 40px;
    display: inline-block;
    text-decoration: none
}

.product .tab a.active,
.product .tab a:hover {
    color: #00a7eb;
    border-bottom: 2px solid #00a7eb;
}

.product .title {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 50px;
}

.product .title .p1 {
    font-size: 28px;
    color: #1f2b41;
}

.product .title .p2 {
    margin-top: 50px;
    font-size: 16px;
    line-height: 32px;
}
.contain {
    width: 1440px;
    margin: 0 auto;
}
.product .contain {
	width: 1200px;
}
.clear {
    zoom: 1;
}
.product .part1 .main li {
    width: 380px;
    margin-right: 30px;
    padding: 30px;
    margin-bottom: 30px;
    float: left;
    box-sizing: border-box;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 5px 10px 5px rgba(219, 234, 255, 1);
    text-align: center;
}
.product .part1 .main li img {
	margin-bottom: 25px;
}

.product .part1 .main li .p1 {
	font-size: 18px;
	font-weight: bold;
	color: #1f2b41;
	margin-bottom: 20px;
}

.product .part1 .main li .p2 {
	line-height: 22px;
	color: #8592aa;
}
.product .part1 .main li:nth-of-type(3n) {
	margin-right: 0;}
    .product .part1 .main li:nth-of-type(1) .p2,
.product .part1 .main li:nth-of-type(2) .p2,
.product .part1 .main li:nth-of-type(3) .p2 {
	height: 44px;
	overflow: hidden;
}

.product .part1 .main li:nth-of-type(4) .p2,
.product .part1 .main li:nth-of-type(5) .p2,
.product .part1 .main li:nth-of-type(6) .p2 {
	height: 88px;
	overflow: hidden;
}
img {
    max-width: 100%;
    height: auto;
}
.img{
    text-align: center;
}
</style>
