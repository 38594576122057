<template>
    <div>
        <div class="header">
            <div class="contain clear">
                <a href="" class="logo"><img :src="logo"></a>
                <div class="right fr">
                    <ul class="nav fl">
                        <li class="nav-item" v-for="(i,index) in menu.data" :key="i.data" :class="index == navIndex&&'active'">
                            <div class="demo" v-if="i.url != null">
                                <router-link :to="i.url">
                                <a > {{ i.name }} </a>
                                </router-link>
                            </div>
                            <div class="demo" v-else>
                                <a>{{ i.name }}</a>
                            </div>
                            <ul class="show" v-show="showPcNav" v-if="i.childMenu.length != 0">
                                <li class="nav-item" v-for="a in i.childMenu">
                                    <a @click="go(a.url)">{{ a.name }}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <!-- <div class='search'>
                        <a href="javascript:;" class="to_search"></a>
                        <form action="" id="search_form">
                            <div class="show clear">
                                <input type="text" placeholder="输入搜索内容" name="s" value="">
                                <a href="javascript:;" onclick="$('#search_form').submit();">搜索</a>
                            </div>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="mobile-header">
            <div class="flex-h-center">
                <a href="" class="logo"><img :src="logo"></a>
                <img class="menu" v-if="showMenu" @click="showMenu = !showMenu" src="../../../assets/m_menu2.png" alt="">
                <img class="menu" v-else @click="showMenu = !showMenu" src="../../../assets/m_menu1.png" alt="">
            </div>
            <div class="fix-header" :class="showMenu&&'show-fix-header'">
                <ul>
                    <li @click="showMenu = false" class="small-nav" v-for="i in menu.data" :key="i.data">
                        <router-link :to="i.url">
                            <a> {{ i.name }} </a>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Nav",
    data() {
        return {
            menu: [],
            showMenu: false,
            showPcNav: true,
            logo: ''
        };
    },
    computed: {
        navIndex(){
            let i = 0
            const path = this.$route.path
            const datekey = this.$route.query.datekey
            if(path == '/') return 0
            try {
                this.menu.data.forEach((item,index) => {
                    if(item.url.indexOf(datekey) > -1 || item.url.indexOf(path+'?') > -1){
                        i = index
                        throw new Erorr()
                    }else if(item.childMenu && item.childMenu.find(child => child.url.indexOf(datekey) > -1||child.url.indexOf(path + '?') > -1)){
                        i = index
                        throw new Erorr()
                    }
               })
                
            } catch (error) {
                
            }
           return i
        }
    },
    methods: {
        go(url) {
            let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
            let path = url.split('?')[0]
            let paramter = url.split('=')[1]
            if (rule.test(url)) {
                window.open(url, '_blank')
                this.isDown = false;
                this.Downed = false
            } else {
                console.log(url)
                this.$router.push(url)
                this.showPcNav = false
                setTimeout(() => {
                    this.showPcNav = true
                }, 100);
                // this.$router.push({ path: `${path}`, query: { datekey: paramter } });
                // this.isDown = false;
                // this.Downed = false
            }
        },
    },
    mounted() {
        this.$store.state.data.then((res) => {
            console.log(res)
            res.data.data.map((el) => {
                if (el.name == "导航") {
                    this.menu = el
                }
                if(el.mouldSectionKey == '1195c3e5-fd2a-4fb1-b12b-7f4ba4d07691'){
                    this.logo = el.data[4].picUrl
                }
            })
        })
    }
};
</script>

<style  scoped>
a {
    text-decoration: none;

    color: inherit;
    display: block;
    transition: all 0.3s;
}

.header {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    transition: all 0.5s;
    background: rgba(11, 17, 35, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
}

.header:hover {
    background: rgba(11, 17, 35, 0.6);
}

.header .logo {
    float: left;
    height: 100px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.header .logo img {
    max-height: 100%;
}

.logo img {}

.header .nav {
    margin-right: 35px;
}

.header .nav>.nav-item {
    float: left;
    position: relative;
    padding: 0 20px;
}

.header .nav>.nav-item>.demo>a {
    font-size: 16px;
    color: #fff;
    /* width: 75px; */
    text-align: center;
    line-height: 96px;
    position: relative;
    border-bottom: 4px solid transparent;
}

.header .nav>.nav-item.active>a {
    text-emphasis: none;
}

.header .nav>.nav-item>.demo>a:hover,
.header .nav>.active>.demo>a {
    color: #fff;
    font-weight: bold;
    border-bottom: 4px solid #fff;
}

.header .nav>.nav-item .show {
    position: absolute;
    top: 100px;
    z-index: 3;
    opacity: 0;
    transform: translate(0, -20px);
    visibility: hidden;
    transition: 0.15s all;
    width: 150px;
    text-align: center;
    left: 50%;
    margin-left: -75px;
    background: rgba(11, 17, 35, 0.6)
}

.header .nav>.nav-item:hover>.show {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
}
.header .nav>.nav-item:hover>.disable-show{
    display: none;
}
.header .nav>.nav-item .show .nav-item {
    position: relative;
}

.header .nav>.nav-item .show .nav-item>a {
    line-height: 44px;
    text-align: center;
    position: relative;
    padding: 0 25px;
    color: #fff;
    font-size: 14px;
}

.header .nav>.nav-item .show .show .nav-item>a {
    line-height: 40px;
}

.header .nav>.nav-item .show .nav-item:hover>a {
    color: #fff;
    background: #00a7eb;
}

.header .nav>.nav-item .show .show>.nav-item:first-child a {
    border-radius: 8px 8px 0 0
}

.header .nav>.nav-item .show .show>.nav-item:last-child a {
    border-radius: 0 0 8px 8px;
}

.header .nav>.nav-item .show .show a {
    background: rgba(255, 255, 255, 0.8);
}

.header .nav>.nav-item .show .show a:hover {
    background: rgba(255, 255, 255, 0.8);
    color: #fff;
}

.header .nav>.nav-item .show .show a:after {
    display: none;
}

.header .nav>.nav-item .show .nav-item:hover>a:after {
    opacity: 1;
}

.header .nav>.nav-item .show .show {
    position: absolute;
    left: 100%;
    padding-left: 10px;
    width: max-content;
    min-width: 140px;
    transform: translate(0, 0);
    opacity: 0;
    margin-left: 0;
    visibility: hidden;
    background: none;
    transition: 0.3s all;
    top: 0;
}

.header .nav>.nav-item .show .nav-item:hover>.show {
    opacity: 1;
    visibility: visible;
}

.header .search {
    float: left;
}

.header .search .to_search {
    width: 30px;
    height: 30px;
    margin-top: 35px;
    background: url(../../../assets/search.png) no-repeat center;
}

.header .search .show {
    position: absolute;
    right: 0;
    top: 100px;
    z-index: 2;
    display: none;
    background: #fff;
}

.header .search .show input {
    width: 280px;
    float: left;
    line-height: 50px;
    height: 50px;
    font-size: 14px;
    text-indent: 20px;
}

.header .search .show a {
    float: right;
    width: 60px;
    color: #fff;
    line-height: 50px;
    text-align: center;
    background: #00a7eb;
}

.header .contain {
    position: relative;
}

.fr {
    float: right;
}

.fl {
    float: left;
}
.flex-h-center{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.clear {
    zoom: 1;
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}

.contain {
    width: 1440px;
    margin: 0 auto;
}
.mobile-header{
    padding: 0 20px;
    width: 100vw;
    height: 50px;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    transition: all 0.5s;
    background: rgba(11, 17, 35, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.mobile-header .logo img{
    height: 50px;
}
.fix-header{
    width: 100vw;
    position: absolute;
    top: 50px;
    left: 0;
    height: 0;
    overflow: hidden;
    transition: all .3s;
}
.fix-header ul{
    background-image: -webkit-linear-gradient(top, #61d8ff, #00a7eb);

}
.fix-header::-webkit-scrollbar{
    width: 0;
}
.show-fix-header{
    height: 300px;
    overflow: auto;
}
.small-nav {
    background-image: -webkit-linear-gradient(top, #61d8ff, #00a7eb);
    z-index: 7;
    border-bottom: 1px solid #fff;
    padding: 14px;
    color: #fff;
}

@media screen and (min-width: 1200px){
    .mobile-header{
        display: none;
    }
    .header{
        display: block;
    }
}
@media screen and (max-width: 1200px){
    .mobile-header{
        display: block;
    }
    .header{
        display: none;
    }
}
</style>