<template>
  <div>
    <div class="main">
      <div class="w toppart">
        <div class="content">
          <div class="title">{{ title }}</div>
          <div class="text">{{ titleSubtitle }}</div>
        </div>
      </div>
      <video
        :src="backgronundsource"
        muted
        autoplay
        loop
        playsinline="true"
        preload="auto"
        v-if="flag"
        width="100%"
      />
      <div class="test" :style="{'background-image':`url(${backgronundsource})`}" v-if="flag==false"></div>
      
    </div>
    <div class="jump">
      <div class="w">
        <div class="links">
          <div
            class="link"
            v-for="i in links"
            :key="i.title"
            @click="jumpTo(i.linkUrl)"
          >
            {{ i.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="our w" :id="linkUrl">
      <div class="aboutes">
        <div class="about" v-for="i in list" :key="i.title">
          <div class="title">{{ i.title }}</div>
          <div class="text">
            {{ i.describeInfo }}
          </div>
        </div>
      </div>
      <div class="more" @click="go2(go.linkUrl)">{{ go.title }}</div>
    </div>
    <div class="article" :id="linkUrl2">
      <div class="w">
        <div class="title">{{ titleSubtitle2 }}</div>
        <div class="content">
          <div class="l">
            <p class="sentence" v-html="detailInfo" ></p>
          </div>
          <div class="r">
            <div class="top" ref="pic1"></div>
            <div class="bottom">
              <div class="smalltitle">{{ titleSubtitle3 }}</div>
              <div class="title">{{ describeInfo }}</div>
              <div class="sentence" v-html="detailInfo2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(i, index) in arr1"
      :key="i.title"
      class="steps"
      :id="index == 0 ? linkUrl3 : ''"
      :class="{
        first: index == 0,
        step: index % 2 == 0,
        steps2: index % 2 == 1,
      }"
    > 
      <div class="num">
        <div class="title">{{ i.title }}</div>
        <div class="text">
          {{ i.childrenContent[0].titleSubtitle }}
        </div>
      </div>
      <div class="pic1">
          <img :src="pics[index % 3]" alt="" />
        </div>
      <div class="w">
        <div class="contents">
          <div class="content" v-for="j in i.childrenContent" :key="j.key" v-if="j.title!='标语'">
            <div class="title" @click="go2(j.linkUrl)">{{ j.title }}</div>
            <div class="text">{{ j.titleSubtitle }}</div>
          </div>
        </div>
        
      </div>
      
    </div>
    <div
      v-for="(i, index) in arr2"
      :key="i.title"
      class="steps bggrey"
      :id="index == 0 ? linkUrl4 : ''"
      :class="{
        step: index % 2 == 1,
        steps2: index % 2 == 0,
      }"
    >
    <div class="num">
        <div class="title">{{ i.title }}</div>
        <div class="text">
          {{ i.childrenContent[0].titleSubtitle }}
        </div>
      </div>
              <div class="pic1">
          <img :src="pics[index % 3]" alt="" />
        </div>
       <div class="w">
        <div class="contents">
          <div class="content" v-for="j in i.childrenContent" :key="j.key" v-if="j.title!='标语'">
            <div class="title" @click="go2(j.linkUrl)">{{ j.title }}</div>
            <div class="text">{{ j.titleSubtitle }}</div>
          </div>
        </div>

      </div>
      
    </div>
    <div class="work" :id="linkUrl5">
      <div class="w">
        <div class="title">{{ title2 }}</div>
        <div class="workdata">
          <div class="l">
            <div class="sentence" v-html="detailInfo3"></div>
          </div>
          <div class="r" ref="pic2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pic1 from "@/assets/pic1.png";
import pic2 from "@/assets/pic2.png";
import pic3 from "@/assets/pic3.png";
import pic4 from "@/assets/pic4.png";
import pic5 from "@/assets/pic5.png";
import pic6 from "@/assets/pic6.png";
export default {
  name: "Home",
  data() {
    // flag = 0
    return {
      title: "",
      title2: "",
      titleSubtitle: "",
      titleSubtitle2: "",
      titleSubtitle3: "",
      backgronundsource:"",
      detailInfo: "",
      detailInfo2: "",
      detailInfo3: "",
      describeInfo: "",
      links: [],
      go: {},
      list: [],
      list2: [],
      list3: [],
      pics: [pic1, pic2, pic3],
      pics2: [pic4, pic5, pic6],
      linkUrl: "",
      linkUrl2: "",
      linkUrl3: "",
      linkUrl4: "",
      linkUrl5: "",
      linkUrl6: "",
      flag:'',
      arr1:[],
      arr2:[]
    };
  },
  methods: {
    goTo(url) {
      this.$bus.$emit("go", url);
    },
    jumpTo(name) {
      let height = 0;
      height = document.getElementById(name).offsetTop - 125;
      if (height !== 0) {
        window.scrollTo({
          top: height,
          behavior: "smooth",
        });
      }
    },
     go2(url) {
      let rule=/^(http|https|ftp|file|rtmp|rtsp):\/\//
      let path=url.split('?')[0]
      let paramter=url.split('=')[1]
      if(rule.test(url)){
        window.open(url,'_blank')
        this.isDown = false;
      }else{
        this.$router.push({path:`${path}`,query:{datekey:paramter}});
        this.isDown = false;
      }
    },
  },
      watch: {
        '$route'(val,from){
           console.log(val,from)
           location.reload()
        }
    },
  mounted() {
    let arr
    this.$store.state.data.then((res) => {
      // console.log(res.data.data)
      res.data.data.map(i=>{
        if(i.mouldSectionKey==this.$route.query.datekey){
          arr = i
        }
      })
      this.arr1=arr.data[4].children
      this.arr2=arr.data[5].children
      console.log(this.arr1)
      this.title = arr.data[0].childrenContent[0].title;
      this.titleSubtitle = arr.data[0].childrenContent[0].titleSubtitle;
      arr.data[1].childrenContent.map((i) => {
        this.links.push({ title: i.title, linkUrl: i.linkUrl });
      });
      console.log(this.links)
      arr.data[2].childrenContent.map((i, index) => {
        if (index == 0) {
          this.go = {
            title: i.title,
            linkUrl: i.linkUrl,
            keyValue:i.keyValue
          };
        }
        if (index > 0) {
          console.log(i)
          this.list.push({ title: i.title, describeInfo: i.describeInfo });
        }
      });
      console.log(this.list)
      if(arr.data[0].childrenContent[1].fileUrl.length==null){
        this.backgronundsource = arr.data[0].childrenContent[1].picUrl
      }else{
        this.backgronundsource = JSON.parse(arr.data[0].childrenContent[1].fileUrl)[0].url
      }
      console.log(this.backgronundsource)
      if(this.backgronundsource.endsWith('.mp4')){
        this.flag = true
        // console.log(this.flag)
      }else{
        this.flag = false
        // console.log(this.flag==false)
      }
      this.titleSubtitle2 = arr.data[3].childrenContent[0].titleSubtitle;
      this.detailInfo = arr.data[3].childrenContent[0].detailInfo;
      this.titleSubtitle3 = arr.data[3].childrenContent[1].titleSubtitle;
      this.describeInfo = arr.data[3].childrenContent[1].describeInfo;
      this.detailInfo2 = arr.data[3].childrenContent[1].detailInfo;
      this.$refs.pic1.style.backgroundImage = `url('${arr.data[3].childrenContent[1].picUrl}')`;
      this.$refs.pic1.style.backgroundSize = `cover`;
      arr.data[4].childrenContent.map((i) => {
        this.list2.push({
          title: i.title,
          titleSubtitle: i.titleSubtitle,
          list: JSON.parse(i.keyValue),
        });
      });
      arr.data[5].childrenContent.map((i) => {
        this.list3.push({
          title: i.title,
          titleSubtitle: i.titleSubtitle,
          list: JSON.parse(i.keyValue),
        });
      });
      this.title2 = arr.data[6].childrenContent[0].title;
      this.detailInfo3 = arr.data[6].childrenContent[0].detailInfo;
      this.$refs.pic2.style.backgroundImage = `url('${arr.data[6].childrenContent[0].picUrl}')`;
      this.$refs.pic2.style.backgroundSize = `cover`;
      this.linkUrl = arr.data[2].linkUrl;
      this.linkUrl2 = arr.data[3].linkUrl;
      this.linkUrl3 = arr.data[4].linkUrl;
      this.linkUrl4 =arr.data[5].linkUrl;
      this.linkUrl5 =arr.data[6].linkUrl;
    });
  },
};
</script>

<style scoped>
@media screen and (max-width:750px) {
  .work .r{
    margin-top: .5128rem;
    height: 5.641rem;
  }
  .work{
    background-color: rgb(242, 242, 242);
    padding: .7692rem .3846rem .2564rem;
  }
  .work .sentence{
    font-size: .359rem; 
    margin-top: .5128rem;
  }
  .work .title{
    font-size: .6154rem;
    font-weight: 700;
  }
  .contents{
    padding: .5128rem .3846rem;
    border-top: .1282rem solid #a100ff;
  }
  .contents .content{
    padding: .5128rem;
  }
  .contents .content:not(:last-child){
    border-bottom: 1px solid #959595
  }
  .content .title {
    font-size: .4615rem;
    font-weight: 700;
  }
  .content .text {
    font-size: .359rem;
  }
  .steps .w{
    width: 100%;
  }
  .steps img{
    width: 100%;
  }
   .num{
    background-color: #a100ff;
    padding: .5128rem .3846rem;
    color: #fff;
  }
    .num .title{
    font-size: .5128rem;
    font-weight: 700
  }
    .num .text{
    font-size: .4359rem;
    margin-top: .2564rem;
  }
  .bottom{
    background-color: #fff;
    padding: .641rem 0 ;
  }
  .bottom .title{
    font-size: .4615rem
  }
  .w{
    width: 90%;
  }
  .bggrey .num{
    background-color: #959595;
  }
  .bggrey .contents{
    border-top: .1282rem solid #959595;
  }
  .article .top{
    height: 5.641rem;
    margin-top: .5128rem;
  }
  .smalltitle{
    font-size: .3077rem;
    font-weight: 700;
  }
  .sentence{
    font-size: .359rem;
    font-weight: 550;
  }
  .article .title{
    font-size: .6154rem;
    font-weight: 800;
  }
  .smalltitle .w{
    width: 90%;
  }
  .article{
    background-color: rgb(242, 242, 242);
    padding: 25px 0;
  }
  .toppart{
    background: #a100ff;
    width: 100%;
    padding: 15px 15px .6667rem;
    color: #fff;
  }
   .toppart .title{
    font-size: .9231rem;
    font-weight: 800;
  }
   .toppart .text{
    font-size: .359rem;
    font-weight: 550;
  }
  .links{
    display: none;
  }
  .links .title{
    font-size: 20px
  }
  #option{
    width: 90%;
    margin: auto;
    padding: .5128rem 0;
  }
  .about{
    margin-top: .7692rem
  }
  .about .title{
    font-size: .5128rem;
    font-weight: 700;
  }
  .about .text{
    font-size: .3077rem;
    font-weight: 550;
    margin-top: .2564rem;
  }
  .more{
    margin-top: .5128rem;
    padding: .5128rem 0;
    font-size: .359rem;
    color: #004dff;
    border-top: 1px solid black;
    font-weight: 700
  }
  .work .sentence p{
    font-size: .359rem;
    font-weight: 400
  }
}

@media screen and (min-width:750px) {
  /* .contents .content:nth-child(1){
    
  } */
  .sentence p{
    margin: 0;
  }
  .test{
  width: 100%;
  height: 2.2rem;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 100% auto;
}
.main {
  position: relative;
  margin-top: 0.45rem;
  background-color: black;
  color: white;
  height: 2.2rem;
  overflow: hidden;
  word-break: break-all;
}
.main video {
  width: 100%;
}
.main .content {
  position: absolute;
  top: 0.45rem;
  padding-bottom: 0.35rem;
}
.main .title {
  width: 7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.36rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
}
.main .text {
  width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  font-size: 0.105rem;
}
.jump {
  position: sticky;
  top: 0.45rem;
  background-color: rgb(242, 242, 242);
  border-bottom: 0.01rem solid #959595;
  z-index: 12;
}
.jump .links {
  top: 5rem;
  overflow: hidden;
  text-align: center;
  font-size: 0.09rem;
}
.jump .links .link {
  float: left;
  width: 25%;
  height: 0.35rem;
  line-height: 0.35rem;
  margin-bottom: -0.02rem;
  cursor: pointer;
}
.jump .links .link:hover {
  color: #004dff;
  transition: all 0.4s;
}
.our .aboutes {
  border-bottom: 0.01rem solid #959595;
  overflow: hidden;
  margin-top: 0.24rem;
}
.our .aboutes .about {
  float: left;
  width: calc(90% / 3);
  margin-right: 5%;
  margin-bottom: 0.15rem;
}
.our .aboutes .about:nth-child(3n) {
  margin-right: 0;
}
.our .aboutes .about .title {
  font-size: 0.2rem;
  margin-bottom: 0.12rem;
  font-weight: bold;
}
.our .aboutes .about .text {
  font-size: 0.085rem;
}
.our .more {
  font-weight: bold;
  font-size: 0.1rem;
  margin-top: 0.28rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
  color: #004dff;
}
.our .more:hover {
  color: #5387ff;
  transition: all 0.4s;
}
.article {
  overflow: hidden;
  background-color: rgb(242, 242, 242);
  padding-bottom: 0.2rem;
  word-break: break-all;
}
.article > .w > .title {
  font-size: 0.29rem;
  font-weight: bold;
  padding-top: 0.28rem;
  padding-bottom: 0.17rem;
}
.article .content > .l {
  float: left;
  width: 48%;
  margin-right: 4%;
}
.article .content > .l .sentence {
  font-size: .0875rem;
}
.article .content > .r {
  float: left;
  width: 48%;
}
.article .content > .r .top {
  height: 2rem;
}
.article .content > .r .bottom {
  position: relative;
  background-color: #fff;
  border: 0.01rem solid #ccc;
  padding: 0.14rem;
  margin-left: 0.15rem;
  padding-bottom: 0.4rem;
}
.article .content > .r .bottom::after {
  content: "";
  position: absolute;
  border-top: 0.15rem solid;
  border-left: 0.15rem solid transparent;
  margin-left: 0;
  left: -0.15rem;
  top: -0.01rem;
  color: #000;
}
.article .content > .r .bottom .smalltitle {
  display: inline-block;
  font-size: 0.07rem;
  border-bottom: 0.015rem solid #004dff;
}
.article .content > .r .bottom .title {
  font-size: 0.15rem;
  font-weight: bold;
  margin-top: 0.12rem;
  margin-bottom: 0.12rem;
}
.article .content > .r .bottom .sentence {
  font-size: 0.1rem;
  line-height: 0.155rem;
}
.article .content > .r .bottom .more {
  position: absolute;
  left: 0.12rem;
  bottom: 0.12rem;
  font-size: 0.1rem;
  color: #004dff;
  font-weight: bold;
  cursor: pointer;
}
.article .content > .r .bottom .more:hover {
  color: #5387ff;
  transition: all 0.4s;
}
.steps {
  min-height: 4.5rem;
  position: relative;
  color: white;
  overflow: hidden;
}
.first {
  background: url(../../../assets/bg1.png) no-repeat;
}
.bggrey {
  color: #000;
}
.steps2 {
  background-color: rgb(242, 242, 242);
}
.steps .num {
  position: absolute;
  top: 0.3rem;
  min-height: 1.05rem;
  width: 100%;
  overflow: hidden;
  padding-left: 45%;
  z-index: 9;
  background-color: rgba(161, 0, 255, 0.75);
}

.bggrey .num {
  background-color: hsla(0, 0%, 65.5%, 0.75);
}
.steps2 .num {
  padding-left: 15%;
}
.steps .num .title {
  font-size: 0.22rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  margin-right: 0.3rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.steps .num .text {
  font-size: 0.1rem;
  margin-right: 0.3rem;
  margin-bottom: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 12;
}
.steps .contents {
  position: absolute;
  width: 2.7rem;
  /* height: 3rem; */
  background-color: white;
  border-top: 0.03rem solid #a100ff;
  box-shadow: 0 0.02rem 0.04rem 0 #ccc;
  z-index: 10;
  color: #000;
  padding: 0 0.15rem;
  padding-bottom: 0.15rem;
}
.bggrey .contents {
  border-top: 0.03rem solid #a7a7a7;
}
.steps2 .contents {
  right: 0;
}
.steps .contents .content {
  margin-top: 0.3rem;
  border-bottom: 0.01rem solid #ccc;
}
.steps .contents .content:last-child {
  border-bottom: none;
}
.steps .contents .content .title {
  font-weight: bold;
  font-size: 0.145rem;
  cursor: pointer;
}
.steps .contents .content .title:hover {
  color: #5387ff;
  transition: all 0.4s;
}
.steps .contents .content .text {
  font-size: 0.1rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
.steps .w {
  position: relative;
}
.steps .pic1 {
  position: absolute;
  right: 0;
  top: 1.2rem;
  z-index: 8;
  width: 50%;
}
.steps2 .pic1 {
  left: 0;
  width: 50%;
}
.pic1 img{
  width: 100%;
  height: auto;
}
.work {
  background-color: rgb(242, 242, 242);
  overflow: hidden;
  padding-bottom: 0.3rem;
}
.work .title {
  font-size: 0.28rem;
  font-weight: bold;
  margin-top: 0.25rem;
  margin-bottom: 0.2rem;
}
.work .workdata {
  overflow: hidden;
  min-height: 2rem;
}
.work .workdata .l {
  width: 48%;
  float: left;
}
.work .workdata .l .sentence {
  font-size: 0.1rem;
  margin-bottom: 0.2rem;
  line-height: 0.155rem;
}
.work .workdata .r {
  width: 48.5%;
  margin-left: 3.5%;
  height: 2.35rem;
  float: left;
}
}
</style>