<template>
    <div class="joinsus">
        <div class="inside_banner"
            :style="'background-image: url('+top_bg+');'">
            <div class="font">
                <div class="contain">
                    <p class="p1">{{title}}</p>
                    <p class="p2">{{titleSubtitle}}</p>
                </div>
            </div>
            <div class="end">
                <div class="contain clear">
                    <div class="nav fl" :class="i.url.indexOf('/joinsUs') > -1 && 'nav-active'" :key="index"  v-for="(i,index) in end"  @click="go(i.url,index)">
                        <a  >{{ i.name }}</a>
                    </div>
                    <div class="bread fr">
                        <span>位置：</span>
                        <a href="">首页</a>
                        <span>-</span>
                        <a>{{title}}</a>
                        <span>-</span>
                        <a>{{stitle}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="join">
            <div class="top">
                <div class="about_title">
                    <p class="pq">公司福利</p>
                    <p class="pw">Company Benefits</p>
                </div>
                <ul class="main contain">
                    <li v-for="i in aboutTitle">
                        <div class="img">
                            <img :src="i.picUrl">
                        </div>
                        <div class="font">
                            <p class="p1">{{ i.title }}</p>
                            <p class="p2">{{ i.titleSubtitle }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import InsideBanner from "@/components/PC/Common/InsideBanner";
export default {
    name: 'AboutUs',
    components: {
        InsideBanner
    },
    data() {
        return {
            newres: {},
            navigation:{},
            end:[],
            top_bg: "",
            aboutTitle: {},
            tab: {},
            title: '',
            titleSubtitle: '',
            stitle: ''
        };
    },
    mounted() {
        this.$store.state.data.then((res) => {
            console.log(res)
            res.data.data.map((el) => {
                if (el.mouldSectionKey == "7a8dd9ca-8f4a-4ede-9ec9-2b4a63d1f1c4") {
                    this.newres = el
                    this.stitle = el.name
                    this.title = el.data[0].childrenContent[0].title
                    this.titleSubtitle = el.data[0].childrenContent[0].titleSubtitle
                }
                 //导航
                 if (el.mouldSectionKey == "b63a8bd6-21d5-4934-afd2-c0e1fdd04cea") {
                    this.navigation = el
                }
            })
            this.aboutTitle = this.newres.data[1].childrenContent;
            this.top_bg = this.newres.data[0].picUrl
            this.tab = this.newres.data[2].childrenContent;
            this.end = this.navigation.data[4].childMenu;
        })


    },
    watch: {
        '$route'(val, from) {
            console.log(val, from)
            location.reload()
        }
    },
    methods: {
        go(url) {
            let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
            let path = url.split('?')[0]
            let paramter = url.split('=')[1]
            if (rule.test(url)) {
                window.open(url, '_blank')
                this.isDown = false;
            } else {
                this.$router.push({ path: `${path}`, query: { datekey: paramter } });
                this.isDown = false;
            }
        },
    },
}
</script>

<style scoped>
.contain {
    width: 1440px;
    margin: 0 auto;
}

.clear {
    zoom: 1;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.bread {
    margin-right: 25px;
    padding-left: 30px;
    /* background: url(../images/bread.png) no-repeat left; */
}

.bread a {
    display: inline-block;
    color: #fff;
}

.bread span {
    display: inline-block;
    color: #fff;
}

.inside_banner {
    height: 600px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.inside_banner .font {
    height: 100%;
    color: #fff;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.inside_banner .font .p1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.inside_banner .font .p2 {
    font-size: 36px;
    font-weight: bold;
}


.inside_banner .nav a.active:before,
.inside_banner .nav a:hover:before {
    width: 100%;
    left: 0;
}

.inside_banner .nav a {
    float: left;
    color: #fff;
    font-size: 18px;
    margin: 0 40px;
    position: relative;
}





.join .top {
    padding-bottom: 40px;
    overflow: auto;
}
.join .top .main{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.join .top .main li {
    width: 30%;
    float: left;
    margin-bottom: 40px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 10px 20px 10px rgba(230, 235, 242, 1);
    margin-right: 0;
}
.join .top .main li img{
    width: 100%;
}
.join .top .main li:nth-of-type(3n) {
    margin-right: 0;
}

.join .top .main li .font {
    padding: 30px 40px;
}

.join .end {
    background: #f5f6fa;
    font-size: 0px;
    text-align: center;
}

.join .end .tab {
    margin-bottom: 35px;
    display: inline;
}

.join .end .tab a {
    display: inline-block;
    width: 230px;
    padding: 4px;
    font-size: 20px;
    background: #fff;
    color: #1f2b41;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 12px;
}

.join .end .tab a.active,
.join .end .tab a:hover {
    background-image: linear-gradient(#fff, #fff), linear-gradient(to right, #61d8ff, #00a7eb);
    background-clip: content-box, padding-box;
}

.join .end .tab a p {
    padding-bottom: 45px;
}

.join .end .tab a:last-child {
    margin-right: 0;
}

.join .end .tab a img {
    width: 60px;
    height: 54px;
    transition: 0.3s all;
    margin: 35px auto 30px;
    display: block;
}

.join .end .main li {
    width: 706px;
    box-sizing: border-box;
    float: left;
    margin-bottom: 30px;
    position: relative;
    border-radius: 6px;
    background: #fff;
}


.join .end .main li:nth-of-type(2n) {
    float: right;
}


.about_title {
    text-align: center;
    padding: 60px 0;
}

.about_title .pw {
    color: #848a96;
}
</style>

