<template>
    <div class="joins">
        <div class="inside_banner" :style="{ 'background-image': 'url(' + top_bg + ')' }">
            <div class="font">
                <div class="contain">
                    <p class="p1">{{title}}</p>
                    <p class="p2">{{titleSubtitle}}</p>
                </div>
            </div>
            <div class="end">
                <div class="contain clear">
                    <div class="nav fl" :class="i.url.indexOf('/joins?') > -1 && 'nav-active'" v-for="i in end" @click="go(i.url)">
                        <a >{{ i.name }} </a>
                    </div>
                    <div class="bread fr">
                        <span>位置：</span>
                        <a href="">首页</a>
                        <span>-</span>
                        <a>{{title}}</a>
                        <span>-</span>
                        <a>{{stitle}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="about">
            <div class="part1">
                <div class="contain about-us">
                    <div>
                        <div class="title">
                            <!-- <p class="p3">ABOUT</p> -->
                            <p class="p1">关于我们</p>
                            <p class="p2">About Us</p>
                        </div>
                        <div class="font">
                            <div class="font-content">
                                <p v-html="test"></p>
                            </div>
                            <img class="font-img" :src="part1Pic">
                        </div>
                    </div>
                    <!-- <div class="img fr">
                        <img:src="part1Pic">
                    </div> -->
                </div>
                <ul class="list contain">
                    <li v-for="i in example">
                        <p class="p1"><span>{{i.titleSubtitle}}</span></p>
                        <p class="p2">{{i.title}}</p>
                    </li>
                </ul>
            </div>
            <div class="part2" :style="'background-image: url('+part2Bg+');'">
                <div class="clear culture">
                    <div class="ico ico1 left fl">
                        <img width="35" height="35" :src="corporateCulture.childrenContent[2].picUrl"
                            class="attachment-35x35 size-35x35" alt="" loading="lazy">
                        <p class="p1">{{corporateCulture.childrenContent[2].title }}</p>
                        <p class="p2" v-html="corporateCulture.childrenContent[2].titleSubtitle"></p>
                    </div>
                    <div class="img fl">
                        <img width="561" height="561" :src="corporateCulture.childrenContent[1].picUrl"
                            class="attachment-561x561 size-561x561" alt="" loading="lazy" srcset=""
                            sizes="(max-width: 561px) 100vw, 561px">
                        <div class="font">
                            <div>
                                <p class="p3">{{ corporateCulture.childrenContent[1].title }}</p>
                                <p class="p4" v-html="corporateCulture.childrenContent[1].titleSubtitle"></p>
                            </div>
                        </div>
                    </div>
                    <div class="right fr">
                        <div class="ico ico2">
                            <img width="35" height="35" :src="corporateCulture.childrenContent[3].picUrl"
                                class="attachment-35x35 size-35x35" alt="" loading="lazy">
                            <p class="p1">{{ corporateCulture.childrenContent[3].title }}</p>
                            <p class="p2" v-html="corporateCulture.childrenContent[3].titleSubtitle"></p>
                        </div>
                        <div class="ico ico3">
                            <img width="35" height="35" :src="corporateCulture.childrenContent[4].picUrl"
                                class="attachment-35x35 size-35x35" alt="" loading="lazy">
                            <p class="p1">{{ corporateCulture.childrenContent[4].title }}</p>
                            <p class="p2" v-html="corporateCulture.childrenContent[4].titleSubtitle">
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="part4" :style="'background-image: url('+honorBg+');'">
                <div>
                    <div class="about_title">
                        <p class="pq">资质荣誉</p>
                        <p class="pw">Qualification Honor</p>
                        <p class="p1"></p>
                    </div>
                   <swiper v-if="mode=='pc'" class="swiper contain" :options="swiperOption">
                        <swiper-slide v-for="i in honor.data">
                            <a href="javascript:;">
                                    <div class="honor-img">
                                        <img :style="{'width': screenWidth+'px'}" :src="i.picUrl">
                                    </div>
                                    <!-- <p class="p2">{{ i.contentKey }}</p> -->
                                    <!-- <p class="p3">荣誉</p> -->
                                </a>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination-bullets " slot="pagination"></div>
                   </swiper>
                   <swiper v-if="mode=='phone'" class="swiper" :options="swiperOption1">
                        <swiper-slide v-for="i in honor.data">
                            <a href="javascript:;">
                                    <div class="img">
                                        <img :src="i.picUrl">
                                    </div>
                                    <!-- <p class="p2">{{ i.contentKey }}</p> -->
                                    <!-- <p class="p3">荣誉</p> -->
                                </a>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination-bullets " slot="pagination"></div>
                   </swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Swiper,SwiperSlide} from "vue-awesome-swiper"
import {mapState} from 'vuex'
import "swiper/css/swiper.css"
export default {
    name: 'AboutUs',
    components: {
        Swiper,
        SwiperSlide
    },
    computed: {
        ...mapState(['mode'])
    },
    data() {
        return {
            newres: {},
            example:{},
            honor: {},
            top_bg: "",
            navigation: {},
            end: [],
            test: "",
            part1: {},
            part2: {},
            corporateCulture: {childrenContent:[{},{},{},{},{}]},
            arr: [],
            index:'',
            swiperOption:{
                slidesPerView:1,
                spaceBetween:30,
                slidesPerGroup:1,
                loop:true,
                loopFillGroupWithBlank:true,
                // pagination:{
                //     el:".swiper-pagination",
                //     clickable:true,
                // },
            },
            swiperOption1:{
                slidesPerView:1,
                spaceBetween:30,
                slidesPerGroup:1,
                loop:false,
                loopFillGroupWithBlank:true,
                // pagination:{
                //     el:".swiper-pagination",
                //     clickable:true,
                // },
            },
            title: '',
            titleSubtitle: '',
            stitle: '',
            part1Pic: '',
            part2Bg:'',
            honorBg: '',
            screenWidth: document.body.clientWidth
        };
    },
    mounted() {
        this.$store.state.data.then((res) => {
            console.log(res)
            res.data.data.map((el) => {
                //关于多飞数据
                if (el.mouldSectionKey == "1195c3e5-fd2a-4fb1-b12b-7f4ba4d07691") {
                    this.newres = el
                }
                //荣誉板块数据
                if (el.mouldSectionKey == "da0a0dbe-98f7-458b-9aec-79b035ce9ed3") {
                    this.honor = el
                }
                //导航栏数据
                if (el.mouldSectionKey == "b63a8bd6-21d5-4934-afd2-c0e1fdd04cea") {
                    this.navigation = el
                }
            })
            this.stitle = this.newres.name
            this.top_bg = this.newres.data[0].picUrl;
            this.part1 = this.newres.data[1];
            this.test = this.newres.data[1].childrenContent[1].detailInfo;
            this.part1Pic = this.newres.data[1].childrenContent[1].picUrl.indexOf(',') > -1 ? this.newres.data[1].childrenContent[1].picUrl.split(',')[0] : this.newres.data[1].childrenContent[1].picUrl
            this.part2Bg = this.newres.data[2].childrenContent[0].picUrl
            this.corporateCulture = this.newres.data[2];
            this.example = this.newres.data[3].childrenContent;
            //
            this.arr = this.honor.data;
            //
            this.end = this.navigation.data[5].childMenu;
            //
            this.title = this.newres.data[1].childrenContent[1].title;

            this.honorBg = this.newres.data[1].childrenContent[0].picUrl
            this.titleSubtitle = this.newres.data[1].childrenContent[1].titleSubtitle;
        })
        const img = document.querySelector('.font-img')
        let width = img.width
        img.onload = () => {
            this.setWidth()
        }
        setInterval(() => {
            const w = img.width
            if(w != width){
                this.setWidth()
                width = w
            }
        }, 500);
    },
    watch: {
        '$route'(val, from) {
            console.log(val, from)
            location.reload()
        }
    },
    methods: {
        setWidth(){
            const pc = document.querySelector('.pc')
            if(!pc) return
            const fontImg = document.querySelector('.font-img')
            const width = fontImg&&fontImg.width||NaN
            if(!isNaN(width)){
                document.querySelector('.font-content').style.width = `calc(100% - ${width+20}px)`
            }
        },
        on_bot_enter() {
            this.$refs.mySwiper.$swiper.autoplay.stop()
        },
        on_bot_leave() {
            this.$refs.mySwiper.$swiper.autoplay.start()
        },
        go(url) {
            let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
            let path = url.split('?')[0]
            let paramter = url.split('=')[1]
            if (rule.test(url)) {
                window.open(url, '_blank')
                this.isDown = false;
            } else {
                this.$router.push({ path: `${path}`, query: { datekey: paramter } });
                this.isDown = false;
            }
        },
        // subStr(str){
        //     const startIndex = 'key":"';
        //     const endIndex ='",';
        //      this.index = str.index(startIndex,endIndex);
        //     return this.index;
        // }
    },
}
</script>


<style scoped>

img {
    max-width: 100%;
    height: auto;
    border: none;
    vertical-align: top;
}

a {
    text-decoration: none;
    color: inherit;
    display: block;
    transition: all 0.3s;
}

.contain {
    width: 1440px;
    margin: 0 auto;
}

.clear {
    zoom: 1;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.bread {
    margin-right: 25px;
    padding-left: 30px;
    /* background: url(../images/bread.png) no-repeat left; */
}

.bread a {
    display: inline-block;
    color: #fff;
}

.bread span {
    display: inline-block;
    color: #fff;
}

.inside_banner {
    height: 600px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.inside_banner .font {
    height: 100%;
    color: #fff;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.inside_banner .font .p1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.inside_banner .font .p2 {
    font-size: 36px;
    font-weight: bold;
}

.inside_banner .nav a.active:before,
.inside_banner .nav a:hover:before {
    width: 100%;
    left: 0;
}

.inside_banner .nav a {
    float: left;
    color: #fff;
    font-size: 18px;
    margin: 0 40px;
    position: relative;
    text-decoration: none;
}





.about .part1 {
    margin: 0 auto;
    padding: 70px 0;
    /* background: url(../images/about_1.png) no-repeat center; */
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
    overflow: auto;

}



.about .part1 .p3 {
    font-size: 150px;
    position: absolute;
    left: 0;
    line-height: 110px;
    color: #1f2b41;
    opacity: 0.05;
    top: 0;
    font-family: "ph_b";
}

.scrollBar {
    padding-right: 20px;
}

.scrollBar::-webkit-scrollbar-track-piece {
    background-color: #ececec;
}

.scrollBar::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.scrollBar::-webkit-scrollbar-thumb {
    background-color: #00a7eb;
    background-clip: padding-box;
    min-height: 28px;
}

.about .part1 .list{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}
.about .part1 .list li {
    width: 20%;
    background: #fff;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
    float: left;
    text-align: center;
    padding: 25px 0;
    /* margin-right: 40px; */
    border-radius: 0 20px 0 20px;
}

.about .part1 .list li .p1 {
    font-size: 50px;
    font-weight: bold;
    font-family: Arial;
}

.about .part1 .list li .p1 span {
    -webkit-background-clip: text;
    background-image: -webkit-linear-gradient(left, #61d8ff, #00a7eb);
    -webkit-text-fill-color: transparent;
}

.about .part1 .list li .p2 {
    line-height: 20px;
}

.about .part1 .list li:last-child {
    margin-right: 0;
}

.about .part2 {
    background: url(https://wxstore.cqczy.net/upload/shopfactory/20230509/1624426578003c.png) no-repeat center fixed;
    background-size: cover;
    padding: 70px 0;
    color: #fff;
    overflow: auto;

}

.about .part2 .img {
    margin: 0 66px;
}

.about .part2 .p1 {
    font-size: 20px;
    font-weight: bold;
}

.about .part2 .contain {
    width: 1000px;
}

.about .part2 .left {
    text-align: right;
}

.about .part2 .p1 {
    font-size: 20px;
    margin: 20px 0;
    font-weight: bold;
}

.about .part2 .p2 {
    font-size: 16px;
    line-height: 30px;
}

.about .part2 .right {
    margin-top: 105px;
}

.about .part2 .ico2 {
    margin-bottom: 100px;
}

.about .part2 .img {
    position: relative;
}

.about .part2 .img .font {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.about .part2 .img .font .p3 {
    font-size: 32px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
}

.about .part2 .img .font .p4 {
    font-size: 16px;
}


.about .part4 {
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 60px;
    /* background: url(../images/about4.png) no-repeat center fixed; */
    background-size: cover;
}

.about_title {
    text-align: center;
    padding: 60px 0;
}

.about_title .pw {
    color: #848a96;
}

.about .part4 .about_title * {
    color: #fff;
}

.about .part4 .p2 {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 15px;
    color: #fff;
}

.about .part4 .p3 {
    color: #868e9d;
    width: 73px;
    border: 1px solid #868e9d;
    border-radius: 30px;
    line-height: 30px;
    margin: 0 auto;
    text-align: center;
}

.about .part4 .swiper-pagination-bullet {
    width: 30px;
    height: 30px;
    border: 1px solid #fff;
    line-height: 30px;
    opacity: 1;
    background: inherit;
    color: #fff;
    border-radius: 4px;
}

.about .part4 .swiper-pagination-bullet-active {
    background: #fff;
    color: #001353;
}

.about .part4 .swiper-pagination {
    position: initial;
    margin-top: 40px;
}
.joins .about .part1 .contain{
    display: flex;
}
.joins .about .part1 .left{
    margin-right: 20px;
}
.joins .about .part2 .contain{
    display: flex;
    align-items: center;
}
.joins .about .part2 .ico img{
    max-width: 200px;
}
.joins .about .part2 .img{
    max-width: 50%;
}
.honor-img{
    text-align: center;
}
.honor-img img{
    width: 100%;
}
.culture{
    padding: 0 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-us{
    justify-content: space-between;
}
</style>

