<template>
    <div class="contactus">
        <div class="inside_banner" :style="{ 'background-image': 'url(' + top_bg + ')' }">
            <div class="font">
                <div class="contain">
                    <p class="p1">{{title}}</p>
                    <p class="p2">{{titleSubtitle}}</p>
                </div>
            </div>
            <div class="end">
                <div class="contain clear">
                    <div class="nav fl" :class="i.url.indexOf('/contactUs?') > -1 && 'nav-active'" v-for="i in end" @click="go(i.url)">
                        <a >{{ i.name }}</a>
                    </div>
                    <div class="bread fr">
                        <span>位置：</span>
                        <a href="">首页</a>
                        <span>-</span>
                        <a>{{title}}</a>
                        <span>-</span>
                        <a>{{stitle}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact">
            <div class="top">
                <div class="contain">
                    <div class="left fl">
                        <div class="title">
                            <p class="p1">公司总部</p>
                            <p class="p2">欢迎您随时通过以下方式联系我们。</p>
                        </div>

                        <ul class="main">
                            <li v-for="i in contactUs">
                                <div class="font fl">
                                    <p class="p3">{{ i.title }}</p>
                                    <div class="p3" v-html="i.detailInfo"></div>
                                    <p class="p5"></p>
                                </div>
                                <div class="img fr" >
                                    <img :src="i.picUrl">
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="img fr">
                        <img width="480" height="600"
                            :src="companyPic">
                    </div>
                </div>
            </div>
            <div class="end contain">

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUs',
    data() {
        return {
            newres: {},
            navigation:{},
            demo:'',
            top_bg: "",
            end: [],
            contactUs: {},
            title: '',
            titleSubtitle: '',
            stitle: '',
            companyPic: ''
        };
    },
    mounted() {
        this.$store.state.data.then((res) => {
            console.log(res)
            res.data.data.map((el) => {
                //联系我们  
                if (el.mouldSectionKey == "e8382e6a-a24f-4577-b25a-63f4e81887f3") {
                    this.newres = el
                }
                //关于多飞
                if (el.mouldSectionKey == "1195c3e5-fd2a-4fb1-b12b-7f4ba4d07691") {
                    this.demo = el
                }
                //导航栏数据
                if (el.mouldSectionKey == "b63a8bd6-21d5-4934-afd2-c0e1fdd04cea") {
                    this.navigation = el
                }
            })
            this.contactUs = this.newres.data[0].childrenContent;
            this.companyPic = this.newres.data[0].picUrl
            this.top_bg = this.demo.data[0].picUrl;
            this.end = this.navigation.data[5].childMenu;
            this.title = this.demo.data[1].childrenContent[1].title;
            this.titleSubtitle = this.demo.data[1].childrenContent[1].titleSubtitle;
            this.stitle = this.newres.name
        })


    },
    watch: {
        '$route'(val, from) {
            console.log(val, from)
            location.reload()
        }
    },
    methods: {
        go(url) {
            let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
            let path = url.split('?')[0]
            let paramter = url.split('=')[1]
            if (rule.test(url)) {
                window.open(url, '_blank')
                this.isDown = false;
            } else {
                this.$router.push({ path: `${path}`, query: { datekey: paramter } });
                this.isDown = false;
            }
        },
    },
}
</script>

<style scoped>
img {
    max-width: 100%;
    height: auto;
    border: none;
    vertical-align: top;
}
.contain {
    width: 1440px;
    margin: 0 auto;
}

.clear {
    zoom: 1;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.bread {
    margin-right: 25px;
    padding-left: 30px;
    /* background: url(../images/bread.png) no-repeat left; */
}

.bread a {
    display: inline-block;
    color: #fff;
}

.bread span {
    display: inline-block;
    color: #fff;
}

.inside_banner {
    height: 600px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.inside_banner .font {
    height: 100%;
    color: #fff;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.inside_banner .font .p1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.inside_banner .font .p2 {
    font-size: 36px;
    font-weight: bold;
}

.inside_banner .nav a.active:before,
.inside_banner .nav a:hover:before {
    width: 100%;
    left: 0;
}

.inside_banner .nav a {
    float: left;
    color: #fff;
    font-size: 18px;
    margin: 0 40px;
    position: relative;
}





.contain {
    width: 1440px;
    margin: 0 auto;
}

.contact .top {
    padding: 80px 0;
    /* background: url(../images/contact1_bg.png) no-repeat center fixed; */
    background-size: cover;
    overflow: auto;
}

.contact .top .left {
    width: 920px;
}
.contact .top .left .main {
    margin-top: 40px;
}

.contact .top .left {
    width: 920px;
}

.contact .top .left li {
    padding: 37px 30px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 0 10px 5px rgba(244, 247, 250, 1);
    width: 450px;
    float: left;
    margin-right: 20px;
}

.contact .top .left li:nth-of-type(2n) {
    margin-right: 0;
}

.contact .top .left .font {
    padding-left: 33px;
    position: relative;
}

.contact .top .left .font:after {
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
    width: 20px;
    height: 3px;
    background: #00a7eb;
}


.contact .top .left .p4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #1f2b41;
}

.contact .top .left .p5 {
    line-height: 24px;
}

.contact .top .left .font {
    width: 220px;
}

.contact .top .right {
    width: 400px;
}

.contact .top .left li .code {
    margin-top: -14px;
}
.contactus .top .contain{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contactus .top .contain .left {
    margin-right: 20px;
}
.contactus .top .contain .left .main li{
    height: 140px;
}
.contactus .top .contain .left .main li{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 45%;
}
.contactus .top .contain .left .main li img{
    width: 100px;
}
</style>

