<template>
    <!--核心产品导航栏-->
    <div class="inside_banner" :style="{ 'background-image': 'url(' + top_bg + ')' }">
            <div class="font">
                <div class="contain">
                    <p class="p1">关于多飞</p>
                    <p class="p2">ABOUT DOOFFE</p>
                </div>
            </div>
            <div class="end">
                <div class="contain clear">
                    <div class="nav fl" v-for="i in end" @click="go(i.linkUrl)">
                        <a >{{ i.title }}</a>
                    </div>
                    <div class="bread fr">
                        <span>位置：</span>
                        <a href="">首页</a>
                        <span>-</span>
                        <a>关于我们</a>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    name: 'coreNavigation',
    components: {
       
    },
    data() {
        return{
            newres:{},
            top_bg:'',
            end:[]
        }
    },
    methods: {
        
        go(url) {
            let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
            let path = url.split('?')[0]
            let paramter = url.split('=')[1]
            if (rule.test(url)) {
                window.open(url, '_blank')
                this.isDown = false;
            } else {
                this.$router.push({ path: `${path}`, query: { datekey: paramter } });
                this.isDown = false;
            }
        },
    },
    mounted() {
    this.$store.state.data.then((res) => {
      // console.log(res)
      res.data.data.map((el) => {
        //核心产品
        if (el.mouldSectionKey == '04691e5d-bac2-4ae0-b826-5c4cdc206a50') {
          this.newres = el
        }
      })
        //核心产品数据接收
        this.top_bg =  this.newres.data[0].picUrl;
        this.end = this.newres.data[1].childrenContent;

    })
  },

}
</script>
<style scoped>
.inside_banner {
    height: 600px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.inside_banner .font {
    height: 100%;
    color: #fff;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.inside_banner .font .p1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.inside_banner .font .p2 {
    font-size: 36px;
    font-weight: bold;
}


.inside_banner .nav a.active:before,
.inside_banner .nav a:hover:before {
    width: 100%;
    left: 0;
}

.inside_banner .nav a {
    float: left;
    color: #fff;
    font-size: 18px;
    margin: 0 40px;
    position: relative;
    text-decoration: none;
}




.fl {
    float: left;
}

.fr {
    float: right;
}

.bread {
    margin-right: 25px;
    padding-left: 30px;
    /* background: url(../images/bread.png) no-repeat left; */
}

.bread a {
    display: inline-block;
    color: #fff;
}

.bread span {
    display: inline-block;
    color: #fff;
}
</style>