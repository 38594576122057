<template>
    <div style="background-color: rgb(242, 242, 242);padding:.3125rem 0;">
        <div class="content" >
       <h1 class="title">{{title}}</h1>
       <img :src='articlepic' alt="" class="articlepic">
       <div v-html="detailInfo" class="detailInfo"></div>
    </div>  
    </div>
    
</template>

<script>
export default {
    data() {
        return {
            title:'',
            articlepic:'',
            detailInfo:'',
            arr:[]
        }
    },
    methods:{
        
    },
        watch: {
        '$route'(val,from){
           console.log(val,from)
           location.reload()
        }
    },
    mounted(){
        this.$store.state.data.then((res) => {
            // console.log(res)
            // console.log(this.$route.query.interface)
           res.data.data.map((el)=>{
            if(el.mouldSectionKey==this.$route.query.datekey){
              this.arr=el
              console.log(this.arr)
            }
           })
            // console.log(this.arr)
            this.title=this.arr.data.title
            this.articlepic=this.arr.data.picUrl
            this.detailInfo=this.arr.data.detailInfo
        })
  },
};

</script>

<style scoped>
@media screen and (min-width: 750px){
    .content{
    width: 60%;
    margin: auto;
    margin-top: .45rem;

}
.title{
    text-align: center;
    font-size: .3125rem;
}
.articlepic{
    display: block;
    margin: .3125rem auto;
    width: 80%;
    height: auto;
}
.detailInfo{
    padding: .3125rem .3125rem;
    font-size: .125rem;
    margin: auto;
    background-color: #ffffff;
    font-size: .125rem;
}
}
@media screen and (max-width: 750px){
    .title{
        font-size: 1.2308rem;
        text-align: center;
    }
    .articlepic{
        width: 90%;
        height: auto;
        display: block;
        margin: .641rem auto;
    }
    .detailInfo{
        padding: .641rem;
        font-size: .4615rem;
        background-color: #ffffff;
    }
}
</style>
