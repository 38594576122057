import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import axios from "axios";
const BASEURL = window._config.BASEURL
const BASEURL2 = window._config.BASEURL2
const BASEURL3 = window._config.BASEURL3
const debug = window._config.debug
const getUrlParameter = (name) => {
  console.log("地址",name)
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.href);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};
// const id = getUrlParameter("websiteId");
const id = window._config.websiteId;
if (id) {
  localStorage.setItem("websiteId", id);
}
// localStorage.setItem("websiteId", "PUIR4Mw1oHM%3D");
let websiteId = localStorage.getItem("websiteId");
//  axios({
//     url:BASEURL2,
//     method:'post',
//     params:{
//       "websiteId": websiteId
//     }
//   }).then(res=>{
//     res.data.data.map(el=>{
//       mouldSectionKeyList.push({mouldSectionKey:el})
//     })
//   })

export const myRequest = () => {
  return new Promise((resolve, reject) => {
    axios({
      url:BASEURL2,
      method:'get',
      params:{
        "websiteId": websiteId,
        debug
      }
    }).then(res=>{
      let mouldSectionKeyList=[]
      // console.log(res.data)
      res.data.data.map(el=>{
        mouldSectionKeyList.push({mouldSectionKey:el})
      })
      axios({
        url:BASEURL,
        method:'post',
        data:{
          "websiteId": websiteId,
          debug,
          "mouldSectionKeyList":mouldSectionKeyList
        }
      }).then(res=>{
        resolve(res)
        console.log("后端数据：",res.data.data)
      })
    })
  });
};
export const request2 = () =>{
  return new Promise((resolve,reject)=>{
    axios({
      url:BASEURL3,
      method:'post',
      data:JSON.parse(JSON.stringify({"websiteId": websiteId,debug})),
      header:{
        'Content-Type':'application/json'
      }
    }).then(res=>{
        resolve(res)
      // console.log(JSON.stringify({"websiteId": websiteId}))
    })
  })
}
const res2 = request2()
console.log(res2)
const res = myRequest();
const actions = {
  websitename(){
    return new Promise((resolve,reject)=>{
      axios({
        url:BASEURL3,
        method:'post',
        data:JSON.parse(JSON.stringify({"websiteId": websiteId,debug})),
        header:{
          'Content-Type':'application/json'
        }
      }).then(res=>{
          resolve(res)
      })
    })
  }
};
const mutations = {
  // GETDATA(state, value) {
  //   state.data = value;
  // },
  setMode(state,data){
    state.mode = data
  }
};
const state = {
  data: res,
  data2:res2,
  mode:'pc'
};
export default new Vuex.Store({
  actions,
  mutations,
  state,
});
