<template>
  <div class="footer" :style="'background-image:url('+bgImg+')'">
    <div class="top clear contain">
      <ul class="nav fl">
        <li v-for="i in list">
          <div class="a1">{{ i.contentKey }}</div>
          <div class="a2" v-for="a in i.childrenContent" @click="go(a.linkUrl)">
            {{ a.title }}
          </div>
          <a href="" v-for="a in i.childrenContent"></a>
        </li>
      </ul>
      <div class='search fr'>
        <p class="p1">合作咨询</p>
        <!-- <input type="text" placeholder="姓名" name="cooperate_name" id="cooperate_name">
        <input type="text" placeholder="电话" name="cooperate_tel" id="cooperate_tel">
        <textarea placeholder="咨询内容" id="cooperate_desc"></textarea> -->
        <template v-for="item in formList">
          <input type="text" :placeholder="item.title" v-model="item.value" v-if="item.dataType=='text'">
          <textarea v-else-if="item.dataType=='textarea'" :placeholder="item.title" v-model="item.value"></textarea> 
        </template>
        <a href="javascript:;" @click="subcooperate" class='btn'>提交</a>
      </div>
    </div>
    <div class="copy">
      <div class="contain clear">
        <span class='fl'>
          <p>
            <span style="color: #000000;font-size: 12px">
              <span style="color: #ffffff">
                {{ copyright.titleSubtitle }}
              </span>
               <span style="color: #ffffff">
                <a style="color: #ffffff" href="">{{ FilingInformation.titleSubtitle }}
                </a>
              </span>
            </span>
          </p>
        </span>
        <span class='fr' style="font-size: 12px">
          {{ serviceHotline.titleSubtitle }} &nbsp;&nbsp;&nbsp;
          {{ address.titleSubtitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      newres: {},
      company: {},
      copyright: '',
      FilingInformation: '',
      serviceHotline: '',
      address: '',
      list: [],
      formList: [],
      bgImg: ''
    }
  },
  methods: {
    go(url) {
      let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
      let path = url.split('?')[0]
      let paramter = url.split('=')[1]
      if (rule.test(url)) {
        window.open(url, '_blank')
        this.isDown = false;
      } else {
        this.$router.push({ path: `${path}`, query: { datekey: paramter } });
        this.isDown = false;
      }
    },
    subcooperate(){
      const url = window._config.BASEURL4
      axios({
        url,
        method: 'post',
        data: {
          sectionCode: 'da6c0679-03ff-408c-90ee-b315997cdd63',
          websiteId: localStorage.getItem("websiteId"),
          formData: this.formList
        },
        header:{
          'Content-Type':'application/json'
        }
      }).then(res => {
        console.log('表单提交结果',res)
        if(res.data.errno == 0){
          this.$message({
            type: 'success',
            message:'感谢您的咨询，我们将尽快联系您！',
            offset: '100'  
          })
          this.formList.forEach(el => el.value = '')
        }
      })
    },
  },
  mounted() {
    this.$store.state.data.then((res) => {
      // console.log(res)
      res.data.data.map((el) => {
        //
        if (el.mouldSectionKey == '16475b12-e09a-4487-acae-047f645b653a') {
          this.newres = el
        }
        //公司信息
        if (el.mouldSectionKey == '1174e970-7877-480c-b55b-b21c23c6b8e1') {
          this.company = el
        }
        // 表单
        if (el.mouldSectionKey == 'da6c0679-03ff-408c-90ee-b315997cdd63') {
          this.formList = el.data
        }
        // 背景
        if (el.mouldSectionKey == 'a5bf4d3e-5547-45c4-a91c-4551691cb6df') {
          this.bgImg = el.data[0].picUrl
        }
      })
      this.list = this.newres.data;
      //公司信息数据接收
      this.copyright = this.company.data[0].childrenContent[0];
      this.FilingInformation = this.company.data[0].childrenContent[1];
      this.serviceHotline = this.company.data[0].childrenContent[2];
      this.address = this.company.data[0].childrenContent[3];
    })
  },
 
}
</script>

<style scoped >
.contain {
  width: 1440px;
  margin: 0 auto;
}


.clear {
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  clear: both;
}

a {
  text-decoration: none;
  color: inherit;
  display: block;
  transition: all 0.3s;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.footer {
  background-size: cover;
  padding-top: 75px;
}
.footer .nav {
  flex: 1;
  display: flex;
  /* justify-content: space-between; */
}
.footer .nav li {
  float: left;
  margin-right: 100px;
}


.footer .nav li a {
  font-size: 15px;
  line-height: 32px;
  color: #c5c8cd;
}

.footer .nav li .a1 {
  color: #c5c8cd;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
}

.footer .nav>li>.a2 {
  font-size: 15px;
  line-height: 32px;
  color: #c5c8cd;
}

.footer .nav>li>.a2:hover {
  color: #fff;
}

.footer .nav li a:hover {
  color: #fff;
}

.search {
  padding: 1;
}

.footer .search {
  width: 360px;
}

.footer .search .p1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
}

.footer .search input {
  width: 100%;
  line-height: 40px;
  height: 40px;
  border-radius: 6px;
  margin-bottom: 8px;
  background: #4b556b;
  text-indent: 15px;
  color:#fff;
  font-size: 14px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  border: none;
}
.footer .search textarea{
  color:#fff;
  font-size: 14px;
  border: none;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.footer .search textarea:-ms-input-placeholder,
.footer .search input:-ms-input-placeholder {
  color: #c5c8cd;
}

.footer .search textarea::-moz-placeholder,
.footer .search input::-moz-placeholder {
  color: #c5c8cd;
}

.footer .search textarea:-moz-placeholder,
.footer .search input:-moz-placeholder {
  color: #c5c8cd;
}

.footer .search textarea::-webkit-input-placeholder,
.footer .search input::-webkit-input-placeholder {
  color: #c5c8cd;
}

.footer .search textarea {
  width: 100%;
  line-height: 20px;
  height: 65px;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 10px;
  background: #4b556b;
  padding: 10px 15px;
}

.footer .search .btn {
  line-height: 40px;
  background: linear-gradient(to right, #61d8ff, #00a7eb);
  text-align: center;
  color: #fff;
  border-radius: 4px;
}

.footer .top {
  display: flex;
  padding-bottom: 50px;
}

.footer .copy {
  line-height: 70px;
  color: #d0d2d7;
  border-top: 1px solid #424955;
}

.footer .copy a {
  display: inline-block;
}
</style>
