<template>
    <div>
        <div class="inside_banner" :style="{ 'background-image': 'url(' + top_bg + ')' }">
            <div class="font">
                <div class="contain">
                    <p class="p1">关于多飞</p>
                    <p class="p2">ABOUT DOOFFE</p>
                </div>
            </div>
            <div class="end">
                <div class="contain clear">
                    <div class="nav fl" v-for="i in end" @click="go(i.url)">
                        <a class="active">{{ i.name }}</a>
                    </div>
                    <div class="bread fr">
                        <span>位置：</span>
                        <a href="">首页</a>
                        <span>-</span>
                        <a>关于我们</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InsideBanner',
    components: {

    },
    data() {
        return {
            newres: {},
            honor: {},
            top_bg: "",
            end: []
        };
    },
    mounted() {
        this.$store.state.data.then((res) => {
            console.log(res)
            res.data.data.map((el) => {
                if (el.mouldSectionKey == "1195c3e5-fd2a-4fb1-b12b-7f4ba4d07691") {
                    this.newres = el
                }
                //导航栏数据
                if (el.mouldSectionKey == "b63a8bd6-21d5-4934-afd2-c0e1fdd04cea") {
                    this.navigation = el
                }
            })
            console.log("数据", this.newres)
            this.top_bg = this.newres.data[0].picUrl;
            this.end = this.navigation.data[5].childMenu;

        })
    },
    watch: {
        '$route'(val, from) {
            console.log(val, from)
            location.reload()
        }
    },
    methods: {
        go(url) {
            let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
            let path = url.split('?')[0]
            let paramter = url.split('=')[1]
            if (rule.test(url)) {
                window.open(url, '_blank')
                this.isDown = false;
            } else {
                this.$router.push({ path: `${path}`, query: { datekey: paramter } });
                this.isDown = false;
            }
        },
    },
}
</script>


<style scoped>
img {
    max-width: 100%;
    height: auto;
    border: none;
    vertical-align: top;
}

a {
    text-decoration: none;
    color: inherit;
    display: block;
    transition: all 0.3s;
}

.contain {
    width: 1440px;
    margin: 0 auto;
}

.clear {
    zoom: 1;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.bread {
    margin-right: 25px;
    padding-left: 30px;
    /* background: url(../images/bread.png) no-repeat left; */
}

.bread a {
    display: inline-block;
    color: #fff;
}

.bread span {
    display: inline-block;
    color: #fff;
}

.inside_banner {
    height: 600px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.inside_banner .font {
    height: 100%;
    color: #fff;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.inside_banner .font .p1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.inside_banner .font .p2 {
    font-size: 36px;
    font-weight: bold;
}


.inside_banner .nav a.active:before,
.inside_banner .nav a:hover:before {
    width: 100%;
    left: 0;
}

.inside_banner .nav a {
    float: left;
    color: #fff;
    font-size: 18px;
    margin: 0 40px;
    position: relative;
}




.about .part1 {
    margin: 0 auto;
    padding: 100px 0;
    /* background: url(../images/about_1.png) no-repeat center; */
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
    overflow: auto;

}

.about .part1 .img {}

.about .part1 .p3 {
    font-size: 150px;
    position: absolute;
    left: 0;
    line-height: 110px;
    color: #1f2b41;
    opacity: 0.05;
    top: 0;
    font-family: "ph_b";
}

.scrollBar {
    padding-right: 20px;
}

.scrollBar::-webkit-scrollbar-track-piece {
    background-color: #ececec;
}

.scrollBar::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.scrollBar::-webkit-scrollbar-thumb {
    background-color: #00a7eb;
    background-clip: padding-box;
    min-height: 28px;
}

.about .part1 .list li {
    width: 180px;
    background: #fff;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
    float: left;
    text-align: center;
    padding: 25px 0;
    border-radius: 0 20px 0 20px;
    margin-right: 40px;
}

.about .part1 .list li .p1 {
    font-size: 50px;
    font-weight: bold;
    font-family: Arial;
}

.about .part1 .list li .p1 span {
    -webkit-background-clip: text;
    background-image: -webkit-linear-gradient(left, #61d8ff, #00a7eb);
    -webkit-text-fill-color: transparent;
}

.about .part1 .list li .p2 {
    line-height: 20px;
}

.about .part1 .list li:last-child {
    margin-right: 0;
}

.about .part2 {
    background: url(https://wxstore.cqczy.net/upload/shopfactory/20230509/1624426578003c.png) no-repeat center fixed;
    background-size: cover;
    padding: 70px 0;
    color: #fff;
    overflow: auto;

}

.about .part2 .img {
    margin: 0 66px;
}

.about .part2 .p1 {
    font-size: 20px;
    font-weight: bold;
}

.about .part2 .contain {
    width: 1000px;
}

.about .part2 .left {
    text-align: right;
}

.about .part2 .p1 {
    font-size: 20px;
    margin: 20px 0;
    font-weight: bold;
}

.about .part2 .p2 {
    font-size: 16px;
    line-height: 30px;
}

.about .part2 .right {
    margin-top: 105px;
}

.about .part2 .ico2 {
    margin-bottom: 100px;
}

.about .part2 .img {
    position: relative;
}

.about .part2 .img .font {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.about .part2 .img .font .p3 {
    font-size: 32px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
}

.about .part2 .img .font .p4 {
    font-size: 16px;
}


.about .part4 {
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 60px;
    /* background: url(../images/about4.png) no-repeat center fixed; */
    background-size: cover;
}

.about_title {
    text-align: center;
    padding: 60px 0;
}

.about_title .pw {
    color: #848a96;
}

.about .part4 .about_title * {
    color: #fff;
}

.about .part4 .p2 {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 15px;
    color: #fff;
}

.about .part4 .p3 {
    color: #868e9d;
    width: 73px;
    border: 1px solid #868e9d;
    border-radius: 30px;
    line-height: 30px;
    margin: 0 auto;
    text-align: center;
}

.about .part4 .swiper-pagination-bullet {
    width: 30px;
    height: 30px;
    border: 1px solid #fff;
    line-height: 30px;
    opacity: 1;
    background: inherit;
    color: #fff;
    border-radius: 4px;
}

.about .part4 .swiper-pagination-bullet-active {
    background: #fff;
    color: #001353;
}

.about .part4 .swiper-pagination {
    position: initial;
    margin-top: 40px;
}
</style>

