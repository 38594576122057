<template>
  <div class="main">
    <!--静态结构-->
    <div class="myswiper">
      <swiper ref="mySwiper" :options="swiperOption">
        <swiper-slide class="swiper-slide" v-for="i in swiperArr" :key="i.id">
          <div class="bg_main" @mouseenter="on_bot_enter" @mouseleave="on_bot_leave" @click="goto(i.linkUrl)"
               :style="{ 'background-image': `url(${i.picUrl})` }" v-if="i.id">
            <div class="contain">
              <p class="swiper-slide-title">{{ i.title }}</p>
              <p class="swiper-slide-subtitle">{{ i.titleSubtitle }}</p>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>


    <div class="in_case" v-if="dataList[5]&&dataList[5].status == 1">
      <div class="in_title">
        <div>
          <p class="p1">{{planTitle}}</p>
        </div>
      </div>
      <div class="abilityDiv">
        <img class="plan-img" :src="planImg" alt="">
      </div>
    </div>
    
    <div class="in_case" v-if="dataList[1]&&dataList[1].status == 1">
      <div class="in_title">
        <div>
          <p class="p1">我们的能力</p>
        </div>
      </div>
      <div class="abilityDiv">
        <div class="abilityArr" :style="'background:url('+abilityItemBg+')'" v-for="i in abilityArr" :key="i.id">
          <div class="abilityItem">
            <div class="abilityContent">
              <div class="abilityTitle">
                <img class="abilityArrImg" :src="i.picUrl" alt="">
                <div>{{ i.title }}</div>
              </div>
              <div class="abilityDes">{{ i.titleSubtitle }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="in_why" :style="{'background-image':`url(${chooseUsPicUrl})`}"  v-if="dataList[2]&&dataList[2].status  == 1">
      <div class="in_title white_in_title">
        <div>
          <p class="p1">为什么选择我们</p>
        </div>
      </div>
      <ul class="main contain clear">
        <li v-for="i in chooseUsArr" :key="i.id">
          <p class="p1">
            <span data-to="${i.titleSubtitle}" class="timer1" id="count-number0">{{ i.titleSubtitle }}</span>
          </p>
          <p class="p2">{{ i.title }}</p>
        </li>
      </ul>
    </div>


    <div class="in_partner"  v-if="dataList[3]&&dataList[3].status == 1">
      <div class="top">
        <div class="clear contain">
          <div class="title fl">{{custTitle}}</div>
        </div>
      </div>
      <div class="customer-container" :style="{'background-image':`url(${customImgUrl})`}">
        <div class="customer-list">
          <div class="customer-item customer-item1" v-for="(item,index) in customerList" :key="item.id" v-if="index<4">
            <img :src="item.picUrl" alt="">
          </div>
        </div>
        <div class="customer-list">
           <div class="customer-item customer-item2" v-for="(item,index) in customerList" :key="item.id" v-if="index>=4&&index<7">
             <img :src="item.picUrl" alt="">
           </div>
        </div>
        <div class="customer-list">
           <div class="customer-item customer-item3" v-for="(item,index) in customerList" :key="item.id" v-if="index>=7&&index<11">
             <img :src="item.picUrl" alt="">
           </div>
        </div>
      </div>
<!-- 
      <div class="swiper-container gallery-top main swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events">
        <div class="bg" :style="{'background-image':`url(${customImgUrl})`}">
          <div class="contain">
            <div class="font">
              <p class="p1"></p>
              <p class="p2"></p>
            </div>
          </div>
        </div>
        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
      </div> -->

    </div>

    <div class="in_news"  v-if="dataList[4]&&dataList[4].status == 1">
      <div class="in_title">
        <div>
          <p class="p1">品牌新闻</p>
        </div>
      </div>
      <ul class="main contain clear">
        <li v-for="i in newsArr" :key="i.id" @click="handleClick(i)">
          <div class="clear brandnews-item">
            <div class="date fl">
              <span>{{JSON.parse(i.keyValue)[0].value}}</span>
              <em>{{JSON.parse(i.keyValue)[1].value}}</em>
            </div>
            <a href="javascript:;" class="p1 fr">{{ i.title }}</a>
          </div>
          <a href="javascript:;" class="img">
            <img width="181px" height="100px" :src="i.picUrl" class="newsImg"
                 alt="" loading="lazy"> </a>
          <p class="p2">&nbsp; &nbsp;{{ i.titleSubtitle }}</p>
          <p class="p3"><a href="javascript:;">查看详情</a></p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "",
      newres: [],
      swiperArr: [],
      abilityArr: [],
      abilityItemBg: '',
      chooseUsPicUrl: null,
      chooseUsArr: [],
      customImgUrl: null,
      newsArr: [],
      swiperOption: {
        loop: true, //开启循环模式
        navigation: {  //左右切换按钮
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,  //显示个数
        autoplay: { //自动轮播

          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {   //页数小点
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
      },
      customerList:[],
      dataList: [],
      custTitle: '',
      planTitle: '',
      planImg: ''
    }
  },
  components: {},
  computed: {},
  mounted() {
    let that = this
    let dateKey = that.$route.query.datekey
    if (!dateKey) {
      dateKey = 'c2b97c62-d774-4f1e-aabd-b359bf44608e'
    }
    that.$store.state.data.then((res) => {
      res.data.data.map((el) => {
        if (el.mouldSectionKey == dateKey) {
          that.newres = el
        }
      })
      that.dataList = that.newres.data
      that.swiperArr = that.newres.data[0].childrenContent
      that.abilityArr = that.newres.data[1].childrenContent
      that.abilityItemBg = that.newres.data[1].picUrl
      that.chooseUsPicUrl = that.newres.data[2].picUrl
      that.chooseUsArr = that.newres.data[2].childrenContent
      that.customImgUrl = that.newres.data[3].picUrl
      that.newsArr = that.newres.data[4].childrenContent.map(el => {
        let picUrl = el.picUrl
        if(el.picUrl.indexOf(',') > -1){
          picUrl = el.picUrl.split(',')[0]
        }
        return {
          ...el,
          picUrl
        }
      })
      console.log(that.newsArr)
      that.customerList = that.newres.data[3].childrenContent
      that.custTitle = that.newres.data[3].describeInfo
      that.planTitle = that.newres.data[5]?.title
      that.planImg = that.newres.data[5]?.picUrl

      // this.bigtitle = this.newres.data[0].title
      // this.bigsubtitle = this.newres.data[0].titleSubtitle
      // this.backgronundsource = this.newres.data[0].picUrl
      // this.productiontilte = this.newres.data[1].title
      // this.productiontilte2 = this.newres.data[2].title
      // this.productiontilte3 = this.newres.data[3].title
      // this.productiontilte4 = this.newres.data[4].title
      // this.productionname1 = this.newres.data[1].children[0].title
      // this.productionname2 = this.newres.data[1].children[1].title
      // this.productionname3 = this.newres.data[1].children[2].title
      // this.iconpic = this.newres.data[1].children[0].picUrl
      // this.iconpic2 = this.newres.data[1].children[1].picUrl
      // this.iconpic3 = this.newres.data[1].children[2].picUrl
      // this.productionpic1 = this.newres.data[1].children[0].childrenContent[1].picUrl
      // this.productionpic2 = this.newres.data[1].children[1].childrenContent[1].picUrl
      // this.productionpic3 = this.newres.data[1].children[2].childrenContent[1].picUrl
      // this.productionname1title = this.newres.data[1].children[0].title
      // this.productionname1subtitle = this.newres.data[1].children[0].titleSubtitle
      // this.productionname2title = this.newres.data[1].children[1].title
      // this.productionname2subtitle = this.newres.data[1].children[1].titleSubtitle
      // this.productionname3title = this.newres.data[1].children[2].title
      // this.productionname3subtitle = this.newres.data[1].children[2].titleSubtitle
      // this.arr4 = this.newres.data[2].childrenContent
      // for (let i = 1; i < this.newres.data[1].children[0].childrenContent.length; i++) {
      //   this.arr.push(this.newres.data[1].children[0].childrenContent[i])
      // }
      // for (let i = 1; i < this.newres.data[1].children[1].childrenContent.length; i++) {
      //   this.arr2.push(this.newres.data[1].children[1].childrenContent[i])
      // }
      // for (let i = 1; i < this.newres.data[1].children[2].childrenContent.length; i++) {
      //   this.arr3.push(this.newres.data[1].children[2].childrenContent[i])
      // }
      // this.arr5 = this.newres.data[3].childrenContent
      // this.arr6 = this.newres.data[4].childrenContent
      // this.arr7 = this.newres.data[5].childrenContent
    });
  },
  watch: {},
  methods: {
    on_bot_enter() {
      this.$refs.mySwiper.$swiper.autoplay.stop()
    },
    on_bot_leave() {
      this.$refs.mySwiper.$swiper.autoplay.start()
    },
    handleClick(item){
      const url = item.linkUrl
      console.log(item)
      if(url){
        let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
        let path = url.split('?')[0]
        let paramter = url.split('=')[1]
        if (rule.test(url)) {
            window.open(url, '_blank')
            this.isDown = false;
        } else {
            this.$router.push({ path: `${path}`, query: { datekey: paramter,id:item.id } });
            this.isDown = false;
        }
      }
    },
  }
}

</script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
  display: block;
  transition: all 0.3s;
}

.myswiper {
  height: 4.25rem;
}

.swiper-slide {
  height: 4.25rem;
  background-color: gainsboro;
  color: #ffffff;
  width: 100%;
}

.swiper-slide-title {
  margin: 0.5rem 0 0.125rem 0;
  width: 640px;
  height: 93px;
  opacity: 1;
  /** 文本1 */
  font-size: 64px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 92.67px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}

.swiper-slide-subtitle {

  width: 360px;
  height: 24px;
  opacity: 1;
  /** 文本1 */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 23.17px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}

.bg_main {
  padding: .641rem;
  height: 4.25rem;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
}


.in_title {
  text-align: center;
  padding: 65px 0;
}

.in_title .p1 {
  font-size: 32px;
  color: #666666;
  margin-bottom: 10px;
}

.white_in_title .p1 {
  color: #ffff;
}


.in_title .p2 {
  font-size: 16px;
  color: #6f798a;
}

.in_title div {
  display: inline-block;
  padding: 0 120px;
  position: relative;
}

.in_title div:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 78px;
  height: 18px;
  margin-top: -9px;
  background: url(../../../assets/images/in_title1.png) no-repeat center;
}

.in_title div:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 78px;
  height: 18px;
  margin-top: -9px;
  background: url(../../../assets/images/in_title2.png) no-repeat center;
}

.in_case {
  padding-bottom: 70px;
  background: url(../../../assets/images/in_case_bg.png) no-repeat center;
  background-size: cover;
}

.mask_wrap {
  position: relative;
  margin: 0 auto;
  width: 1440px;
  color: #333;
}

.mask_wrap::after {
  content: '';
  display: block;
  height: 1024px;
  width: 3px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #fff;
}

.sol_video {
  width: 1440px;
  height: auto;
}

.sol_img {
  width: 1349px;
  height: auto;
  position: absolute;
  top: 75px;
  left: 43px;
}

.online_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 125px;
  left: 73px;
}

.icon_item {
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 35px;
}

.icon_item:hover img {
  transform: scale(1.2);
}

.icon_item img {
  transition: transform .3s;
  width: 30px;
  height: 30px;
  display: block;
  margin-bottom: 3px;
}

.icon_item span {
  font-size: 12px;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
}

.retail_wrap {
  left: 835px;
}

.offline_wrap {
  top: 233px;
}

.company_wrap {
  left: 835px;
  top: 233px;
}

.in_why {
  padding: 65px 0 180px;
  background-image: url(../../../assets/images/in_why.png);
  background-size: cover;
}

.in_why .main {
  background: #fff;
}

.in_why li {
  float: left;
  padding: 55px 0;
  text-align: center;
  width: 25%;
}

.in_why li .p1 {
  font-family: "Arial";
  font-size: 70px;
  padding-bottom: 20px;
  font-weight: bold;
}

.in_why li .p1 span {
  display: inline-block;
  background-image: -webkit-linear-gradient(left, #61d8ff, #00a7eb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.in_why li .p2 {
  font-size: 20px;
  font-weight: bold;
  color: #666666;
  padding-bottom: 40px;
  background: url(../../../assets/images/in_why_dot.png) no-repeat bottom;
}

.contain {
  width: 1440px;
  margin: 0 auto;
}

.clear {
  zoom: 1;
  display: block;
}

.clear:after {
  content: "";
  display: block;
  clear: both;
}

.in_news {
  padding-bottom: 90px;
  background: url(../../../assets/images/in_news_bg.png) no-repeat center;
  background-size: cover;

}
.in_news .main{
  display: flex;
  justify-content: center;
}
.in_news .main li {
  width: 446px;
  float: left;
  margin-right: 50px;
}

.in_news .main li:nth-of-type(3n) {
  margin-right: 0;
}

.in_news .main li .date {
  width: 70px;
  padding: 10px 0;
  text-align: center;
  color: #fff;
  box-shadow: 0 0 5px 2px rgba(202, 240, 255, 1);
  background: linear-gradient(to right, #61d8ff, #00a7eb);
}

.in_news .main li .date span {
  display: block;
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  font-family: "Arial";
}

.in_news .main li .date em {
  line-height: 20px;
}

.in_news .main li .p1 {
  margin-left: 20px;
  font-size: 16px;
  color: #666666;
  line-height: 24px;
  padding: 11px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-weight: bold;
}

.in_news .main li .img {
  margin: 25px 30%;
  transform: scale(1.1);
}

.in_news .main li .p2 {
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 5px;
  overflow: hidden;
  color: #666666;
  font-size: 16px;
}

.in_news .main li .p3 {
  text-align: center;
  line-height: 50px;
  position: relative;
  border-bottom: 2px solid #f4f4f4;
  color: #666666;
}

.in_news .main li .p3 a {
  display: inline-block;
  /* padding-right: 25px; */
  /* background: url(../../../assets/images/in_news_dot.png) no-repeat right; */
}

.in_news .main li .img {
  overflow: hidden;
}

.in_news .main li .img img {
  transition: 0.3s all;
}

.in_news .main li:hover .img img {
  transform: scale(1.1);
}

.in_news .main li:hover .p3 a,
.in_news .main li:hover .p1 {
  color: #00a7eb;
}

.in_news .main li:hover .p3 a {
  background: url(../../../assets/images/in_news_bg.png) no-repeat right;
}

.in_news .main li .p3:after {
  content: "";
  position: absolute;
  left: 50%;
  height: 2px;
  transition: 0.3s all;
  bottom: 0;
  width: 0;
  background: #00a7eb;
}

.in_news .main li:hover .p3:after {
  left: 0;
  width: 100%;
}

.in_partner {
  position: relative;
}

.in_partner .top {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  background: rgba(9, 29, 74, 0.5);
}

.in_partner .title {
  line-height: 120px;
  font-size: 32px;
  color: #fff;
}

.in_partner .gallery-thumbs {
  width: initial;
  float: right;
}

.in_partner .gallery-thumbs a {
  line-height: 120px;
  margin-left: 25px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  position: relative;
  font-weight: bold;
}

.in_partner .gallery-thumbs .swiper-slide {
  display: inline-block;
  width: initial;
}

.in_partner .gallery-thumbs .swiper-slide-thumb-active a {
  font-size: 22px;
  color: #00a7eb;
  padding-right: 50px;
}

.in_partner .gallery-thumbs .swiper-slide a:after {
  content: "";
  width: 0;
  transition: 0.3s all;
  position: absolute;
  right: 0;
  top: 50%;
  height: 2px;
  margin-top: -1px;
  background: #00a7eb;
}

.in_partner .gallery-thumbs .swiper-slide-thumb-active a:after {
  width: 45px;
}

.in_partner .main .bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 120px;
  padding-bottom: 390px;
}

.in_partner .main .font {
  padding: 65px 0;
}

.in_partner .main .p1 {
  font-size: 32px;
  line-height: 40px;
  width: 735px;
  color: #fff;
  margin-bottom: 10px;
}

.in_partner .main .p2 {
  font-size: 32px;
  width: 735px;
  line-height: 40px;
  color: #fff;
}

.in_partner .main ul {
  width: 735px;
  height: 195px;
}

.in_partner .main li {
  width: 180px;
  height: 60px;
  background: #fff;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.in_partner .main li:nth-of-type(4n) {
  margin-right: 0;
}

.in_partner .swiper-button-next {
}

.in_partner .btn {
  position: absolute;
  bottom: 50px;
  left: 50%;
  z-index: 2;
  margin-left: -740px;
}

.in_partner .btn .swiper-button-prev {
  width: 56px;
  height: 56px;
  position: initial;
  margin-top: 0;
  border-radius: 50%;
  margin-right: 25px;
  float: left;
  left: 0;
  background: url(../../../assets/images/in_partner_dot1.png) no-repeat center #a8b3c6;
}

.in_partner .btn .swiper-button-prev:hover {
  background: url(../../../assets/images/in_partner_dot1.png) no-repeat center #00a7eb;
}

.in_partner .btn .swiper-button-next {
  width: 56px;
  height: 56px;
  position: initial;
  margin-top: 0;
  border-radius: 50%;
  float: left;
  left: 0;
  background: url(../../../assets/images/in_partner_dot2.png) no-repeat center #a8b3c6;
}

.in_partner .btn .swiper-button-next:hover {
  background: url(../../../assets/images/in_partner_dot2.png) no-repeat center #00a7eb;
}

.abilityDiv {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.abilityArr {
  margin-top: 50px;
  width: 26%;
  background-size: 100% 100% !important;
}

.abilityItem {
  min-height: 13vw;
  opacity: 1;
  margin: 0 auto; 
}

.abilityArrImg {
  width: 20px;
  height: 20px;
  opacity: 1;
  display: block;
  margin-right: 10px;
}

.abilityContent {
  width: 90%;
  margin: 0 auto;
  padding-top: 1.8vw;
}
.abilityTitle{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  padding-left: 20px;
  font-weight: bold;
  color: #168cef;
  margin-top: 0.02vw;
}
.abilityDes{
  padding: 2vw 20px;
  font-size: 14px;
  line-height: 20px;
  overflow:hidden;
  text-overflow: ellipsis;
  display:-webkit-box; 
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: #333;
}

.newsTitle {
  display: block;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.newsImg {

}

.in_partner {
  position: relative;
}

.in_partner .top {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  background: rgba(9, 29, 74, 0.5);
}


.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.brandnews-item{
  display: flex;
}
.plan-img{
  width: 90%;
}
</style>




