<template>
  <div class="">Nav</div>
</template>

<script>
import "animate.css";
export default {
  name: "Nav",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
</style>
