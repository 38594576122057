<template>
  <div id="app">
    <div :class="phone?'phone':'pc'">
      <Nav />
      <router-view></router-view>
      <Foot />
      <ul class="right_slide">
        <img :src="icon" class="icon">
        <li>
          <a href="javascript:;" class="a1">
            <i class="ico1"></i>
            <em>电话</em>
          </a>
          <div class="show">
            <div class="main">
              <p class="p1">业务咨询</p>
              <p class="p2">{{tel}}</p>
            </div>
          </div>
        </li>
        <li>
          <a href="javascript:;" class="a1">
            <i class="ico2"></i>
            <em>微信</em>
          </a>
          <div class="show show1">
            <div class="main">
              <dl class="clear">
                <dd>
                  <p class="p3">微信公众号</p>
                  <img :src="wxPic">
                  <p class="p4">扫我吧</p>
                </dd>
              </dl>
            </div>
          </div>
        </li>
        <li @click="handleTop">
          <a href="javascript:;" class="a1 back">
            <i class="ico3"></i>
            <em>顶部</em>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
window.addEventListener("beforeunload", () => {
  window.scrollTo(0, 0);
});
import Nav from "@/components/PC/Nav";
import Nav2 from "@/components/Mobile/Nav";
import Foot from "@/components/PC/Foot";
import Foot2 from "@/components/Mobile/Foot";
export default {
  name: "app",
  components: { Nav, Nav2, Foot2, Foot },
  data() {
    return {
      phone: false,
      tel: '',
      wxPic: '',
      icon: ''
    };
  },
  mounted() {
    this.$store.state.data.then((res) => {
      var link = document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      // link.href = res.data.data[0].data[1].picUrl;
      document.getElementsByTagName("head")[0].appendChild(link);
      res.data.data.forEach(el => {
        if(el.mouldSectionKey == 'fd0ca23c-1543-4b65-9bf6-0bc0cb04931b'){
          this.tel = el.data[0].titleSubtitle
          this.wxPic = el.data[1].picUrl
          this.icon = el.data[2].picUrl
        }
      })
    });
    // this.$router.push("/");
    // if (
    //   navigator.userAgent.match(
    //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    //   ) ||
    //   document.body.clientWidth < 1000
    // ) {
    //   this.phone = true;
    // } else {
    //   this.phone = false;
    // }
    this.phone = window.innerWidth < 1200
    window.addEventListener('resize',e => {
      this.phone = e.target.innerWidth < 1200
    })
  },
  watch: {
    phone(val){
      this.$store.commit('setMode',val?'phone':'pc')
    }
  },
  methods: {
    handleTop(){
      document.documentElement.scrollTop = 0
    }
  },
};
</script>
<style>
* {
  word-break: break-all;
  /* user-select: none; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  width: 100%;
}
.w {
  width: 7.3rem;
  margin: 0 auto;
}
ul {
  list-style: none;
}
.w2 {
  width: 9.1rem;
  margin: 0 auto;
}
.phone {
  overflow-y: hidden;
}
</style>