import VueRouter from "vue-router";
import Home from "@/components/PC/Home";
import About from "@/components/PC/About";
import BrandNews from '@/components/PC/BrandNews';
import BrandNewsDetail from '@/components/PC/BrandNews/brandNewsDetail';
import MediaNews from '@/components/PC/MediaNews';
import SpecialResearch from '@/components/PC/SpecialResearch';
import Joins from '@/components/PC/Joins';
import JoinsUs from '@/components/PC/JoinsUs';
import ContactUs from '@/components/PC/ContactUs';
import News1 from '@/components/PC/News/new1.vue';
import News2 from '@/components/PC/News/new2.vue';
import CoreOne from '@/components/PC/CoreProduct/one.vue';
import CoreTwo from '@/components/PC/CoreProduct/two.vue';
import CoreThree from '@/components/PC/CoreProduct/three.vue';
import CoreFour from '@/components/PC/CoreProduct/four.vue';
import NumericaOne from '@/components/PC/NumericalOperation/one.vue';
import SolutionOne from '@/components/PC/Solution/one.vue';
import SolutionTwo from '@/components/PC/Solution/two.vue';
import SolutionThree from '@/components/PC/Solution/three.vue';
import SolutionFour from '@/components/PC/Solution/four.vue';


import Particular from '@/components/PC/Particular';
import Home2 from "@/components/Mobile/Home";
import About2 from "@/components/Mobile/About";
import Detail2 from "@/components/Mobile/Detail";
import Joins2 from '@/components/Mobile/Joins';
import NewHome from '@/components/PC/NewHome';
import store from '../store/index'

import CoreProduct from '@/components/PC/CoreProduct';
import Solution from '@/components/PC/Solution';
import Job from '@/components/PC/Job';
import JobDetail from '@/components/PC/Job/jobDetail';


let router = new VueRouter({
  routes: [
    {
      path: "/home",
      component: Home,
      meta: { isAuto: true, name: "区块链" },
    },

    {
      path: "/about",
      component: About,
      meta: { isAuto: true, name: "关于区块链" },
    },
    {
      path: "/brandNews",
      component: BrandNews,
      meta: { isAuto: true, name: "公司新闻" },
      props: true,
    },
    {
      path: "/brandnewsdetail",
      component: BrandNewsDetail,
      meta: { isAuto: true, name: "品牌新闻" },
      props: true,
    },
    {
      path: "/mediaNews",
      component: MediaNews,
      meta: { isAuto: true, name: "媒体报道" },
      props: true,
    },
    {
      path: "/specialResearch",
      component: SpecialResearch,
      meta: { isAuto: true, name: "专题研究" },
      props: true,
    },
    {
      path: "/news1",
      component: News1,
      meta: { isAuto: true, name: "新闻" },
      props: true,
    },
    {
      path: "/news2",
      component: News2,
      meta: { isAuto: true, name: "新闻" },
      props: true,
    },
    {
      path:"/coreOne",
      component:CoreOne,
      meta:{isAuto:true,name:"核心产品"},
      props:true,
    },
    {
      path:"/coreTwo",
      component:CoreTwo,
      meta:{isAuto:true,name:"核心产品"},
      props:true,
    },
    
    {
      path:"/coreThree",
      component:CoreThree,
      meta:{isAuto:true,name:"核心产品"},
      props:true,
    },
    {
      path:"/coreFour",
      component:CoreFour,
      meta:{isAuto:true,name:"核心产品"},
      props:true,
    },
    {
      path:"/numericaOne",
      component:NumericaOne,
      meta:{isAuto:true,name:"数智运营"},
      props:true,
    },
    
    {
      path:"/solutionOne",
      component:SolutionOne,
      meta:{isAuto:true,name:"解决方案"},
      props:true,
    },
    
    {
      path:"/solutionTwo",
      component:SolutionTwo,
      meta:{isAuto:true,name:"解决方案"},
      props:true,
    },
    
    {
      path:"/solutionThree",
      component:SolutionThree,
      meta:{isAuto:true,name:"解决方案"},
      props:true,
    },
    
    {
      path:"/solutionFour",
      component:SolutionFour,
      meta:{isAuto:true,name:"解决方案"},
      props:true,
    },
    {
      path: "/joins",
      component: Joins,
      meta: { isAuto: true, name: "关于多飞" },
      props: true,
    },
    {
      path: "/joinsUs",
      component: JoinsUs,
      meta: { isAuto: true, name: "加入我们" },
      props: true,
    },
    {
      path: "/contactUs",
      component: ContactUs,
      meta: { isAuto: true, name: "联系我们" },
      props: true,
    },
    {
      path: "/particular",
      component: Particular,
      meta: { isAuto: true, name: "客户案例" },
      props: true,
    },
    {
      path: "/",
      component: NewHome,
      meta: { isAuto: true},
      props: true,
    },
    {
      path: "/coreproduct",
      component: CoreProduct,
      meta: { isAuto: true, name: "核心产品" },
      props: true,
    },
    {
      path: "/solution",
      component: Solution,
      meta: { isAuto: true, name: "解决方案" },
      props: true,
    },
    {
      path: "/job",
      component: Job,
      meta: { isAuto: true, name: "招聘信息" },
      props: true,
    },
    {
      path: "/jobdetail",
      component: JobDetail,
      meta: { isAuto: true, name: "招聘信息" },
      props: true,
    },
  ],
  mode: 'hash',
  beforeEach: (async(to, from, next) => {
    if(!store.state.data2.title){
      await store.dispatch('websitename').then(res=>{
        if(res&&res.data&&res.data.data){
          name=res.data.data.title
        }
      })
      next()
    }
    next()
  }),
});

// router.afterEach((to) => {
//   // document.title = to.meta.name||name;
//   document.title = name;
//   window.scrollTo(0, 0);
// });
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
