<template>
    <div class="specialsearch">
        <div class="inside_banner" :style="{ 'background-image': 'url(' + top_bg + ')' }">
            <div class="font">
                <div class="contain">
                    <p class="p1">{{title}}</p>
                    <p class="p2">{{titleSubtitle}}</p>
                </div>
            </div>
            <div class="end">
                <div class="contain clear">
                    <div class="nav fl" v-for="i in end" @click="go(i.url)" :class="i.url.indexOf('specialResearch') > -1 && 'nav-active'">
                        <a >{{ i.name }}</a>
                    </div>
                    <div class="bread fr">
                        <span>位置：</span>
                        <a href="">首页</a>
                        <span>-</span>
                        <a>{{title}}</a>
                        <span>-</span>
                        <a>{{stitle}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="news">
            <ul class="main clear">
                <li v-for="i in data" @click="handleClick(i)">
                    <a href="javascript:;" class="img">
                        <img width="450" height="155" :src="i.picUrl" class="attachment-450x270 size-450x270" alt=""
                            loading="lazy">
                    </a>
                    <div class="font">
                        <div class="date fl">
                            <p class="p1">{{i.year}}</p>
                            <p class="p2">{{ i.date }}</p>
                        </div>
                        <a href="javascript:;" class="a1">{{ i.title }}</a>
                    </div>
                </li>
            </ul>
            <ul class="paging">
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BrandNews',
    components: {

    },
    data() {
        return {
            newres: {},
            navigation: {},
            end: [],
            top_bg: '',
            data: {},
            title: '',
            titleSubtitle: '',
            stitle: ''
        };
    },
    mounted() {
        this.$store.state.data.then((res) => {
            console.log(res)
            res.data.data.map((el) => {
                if (el.mouldSectionKey == "0bffc2ec-bc59-4a1f-ae4a-fb472ed833ab") {
                    this.stitle = el.name
                    this.newres = el
                    this.title = el.data[0].childrenContent[0].title
                    this.titleSubtitle = el.data[0].childrenContent[0].titleSubtitle
                }
                //导航
                if (el.mouldSectionKey == "b63a8bd6-21d5-4934-afd2-c0e1fdd04cea") {
                    this.navigation = el
                }
            })
            this.top_bg = this.newres.data[0].picUrl;
            this.data = this.newres.data[1].childrenContent.map(el => {
                let picUrl = el.picUrl
                if(el?.picUrl?.indexOf(',') > -1){
                  picUrl = el?.picUrl.split(',')[0]
                }
                var year = '', date = ''
                if (el?.describeInfo) {
                    const d = el.describeInfo?.split(',')[0]
                    year = d.slice(0,4)
                    date = d.slice(5)
                }
                return {
                    ...el,
                    picUrl,
                    date,
                    year
                }
            })
            this.end = this.navigation.data[3].childMenu;
        })
    },
    watch: {
        '$route'(val, from) {
            console.log(val, from)
            location.reload()
        }
    },
    methods: {
        go(url) {
            let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
            let path = url.split('?')[0]
            let paramter = url.split('=')[1]
            if (rule.test(url)) {
                window.open(url, '_blank')
                this.isDown = false;
            } else {
                this.$router.push({ path: `${path}`, query: { datekey: paramter } });
                this.isDown = false;
            }
        },
        handleClick(item){
            console.log(item)
            const url = item.linkUrl || ''
            let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
            let path = url.split('?')[0]
            let paramter = url.split('=')[1]
            if (rule.test(url)) {
                window.open(url, '_blank')
                this.isDown = false;
            } else {
                this.$router.push({ path: `${path}`, query: { datekey: this.$route.query.datekey|| '',id:item.id,type:'specialresearch' } });
                this.isDown = false;
            }
        }
    },
}
</script>


<style scoped>
img {
    max-width: 100%;
    height: auto;
    border: none;
    vertical-align: top;
}

a {
    text-decoration: none;
    color: inherit;
    display: block;
    transition: all 0.3s;
}

li {}

.contain {
    width: 1440px;
    margin: 0 auto;
}

.clear {
    zoom: 1;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.bread {
    margin-right: 25px;
    padding-left: 30px;
    /* background: url(../images/bread.png) no-repeat left; */
}

.bread a {
    display: inline-block;
    color: #fff;
}

.bread span {
    display: inline-block;
    color: #fff;
}

.inside_banner {
    height: 600px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.inside_banner .font {
    height: 100%;
    color: #fff;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.inside_banner .font .p1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.inside_banner .font .p2 {
    font-size: 36px;
    font-weight: bold;
}

.inside_banner .nav a.active:before,
.inside_banner .nav a:hover:before {
    width: 100%;
    left: 0;
}

.inside_banner .nav a {
    float: left;
    color: #fff;
    font-size: 18px;
    margin: 0 40px;
    position: relative;
}





.news .main {
    overflow: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.news .main li {
    margin-top: 55px;
    width: 450px;
    float: left;
    margin-right: 45px;
}

.news .main li:nth-of-type(3n) {
    margin-right: 0;
}

.news .main li .img {
    width: 100%;
    height: 270px;
    display: flex;
    border-radius: 6px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.news .main li .font {
    padding-top: 25px;
}

.news .main li .font .date {
    width: 85px;
    text-align: center;
    margin-right: 18px;
    border-right: 1px solid #d5d9df;
}

.news .main li .font .date .p1 {
    font-size: 26px;
    color: #666666;
    font-weight: bold;
}

.news .main li .font .date .p2 {
    color: #666666;
}


.news .main li .img img {
    transition: 0.3s all;
}

.news .main li:hover .img img {
    transform: scale(1.1);
}

.news .main li:hover .font .a1 {
    color: #00a7eb;
}

.paging {
    text-align: center;
    padding: 50px 0 75px;
}

.specialsearch .news .main{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: start;
}
.specialsearch .news .main li{
    width: 33%;
    margin: 0;
    box-sizing: border-box;
    padding: 20px;
}
</style>

