export default {
    data() {
        return {
            activeIndex: 0,
            isFixed: false,
            activeTabIndex: 0
        }
    },
    mounted() {
        this.handleScroll()
    },
    methods: {
        handleScroll(){
            const tab= document.querySelector('.tab')
            if(tab){
                const tabTop = document.querySelector('.tab').offsetTop
                window.addEventListener('scroll',(e) => {
                  var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                  this.isFixed = scrollTop > tabTop
                })
            }
        },
        handleTab(id){
            document.querySelector(id).scrollIntoView({
                behavior: "smooth",
                block: 'center',
                inline: 'center'
            })
            this.activeTabIndex = id
        },
    },
}