<template>
    <div>
        <div class="inside_banner"
            :style="'background-image: url('+topbg+')'">
            <div class="font">
                <div class="contain">
                    <p class="p1">{{title}}</p>
                    <p class="p2">{{titleSubtitle}}</p>
                </div>
            </div>
            <div class="end">
                <div class="contain clear">
                    <div :class="i.url.indexOf('/job') > -1 && 'nav-active'"  class="nav fl" v-for="i in end"  @click="go(i.url)">
                        <a  >{{ i.name }}</a>
                    </div>
                    <div class="bread fr">
                        <span>位置：</span>
                        <a href="">首页</a>
                        <span>-</span>
                        <a>{{title}}</a>
                        <span>-</span>
                        <a>{{stitle}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="join">
            <div class="end">
                <div class="contain">
                    <div class="about_title">
                        <p class="pq">招聘列表</p>
                        <p class="pw" style="font-size:14px">Recruiting List</p>
                    </div>
                    <div class="tabs">
                        <div class="tab clear"  v-for="(i,index) in tab" :key="i.id">
                            <a :class="activeDepart == index && 'active'" href="javascript:;" @click="handleChangeDepartment(i,index)">
                                <img :src="i.picUrl">
                                <p>{{ i.title }}</p>
                            </a>
                        </div>
                    </div>
                    <ul class="main clear">
                        <li v-for="item in work" :key="item.id" @click="handleClick(item)">
                            <div class="job-item">
                                <a href="javascript:;">
                                    <p class="p1">{{item.title}}</p>
                                    <p class="p2 clear flex">
                                        <span v-for="(el,i) in item.keyValue" :key="i" v-show="i<3"><em class="ico1">{{el.value}}</em></span>
                                        <div style="clear:both;"></div>
                                    </p>
                                    <p class="p3" v-text="item.describeInfo"></p>
                                    <p class="btn"><span>查看详情</span></p>
                                    <div class="mask"></div>
                                </a>
                            </div>
                        </li>
                        <div style="clear:both;"></div>
                   
                    
                  </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import InsideBanner from "@/components/PC/Common/InsideBanner";
export default {
    name: 'AboutUs',
    components: {
        InsideBanner
    },
    data() {
        return {
            newres: {},
            navigation:{},
            end:[],
            top_bg: "",
            aboutTitle: {},
            tab: {},
            activeDepart: 0,
            work:[],
            title: '',
            titleSubtitle: '',
            stitle: ''
        };
    },
    mounted() {
        this.$store.state.data.then((res) => {
            console.log(res)
            res.data.data.map((el) => {
                if (el.mouldSectionKey == "b5aae133-c8e9-4780-b107-287e7f801e7f") {
                    this.newres = el
                    this.stitle = el.name
                }
                if (el.mouldSectionKey == "7a8dd9ca-8f4a-4ede-9ec9-2b4a63d1f1c4") {
                    this.title = el.data[0].childrenContent[0].title
                    this.titleSubtitle = el.data[0].childrenContent[0].titleSubtitle
                    this.topbg = el.data[2].picUrl
                }
                 //导航
                 if (el.mouldSectionKey == "b63a8bd6-21d5-4934-afd2-c0e1fdd04cea") {
                    this.navigation = el
                }
            })
            // this.aboutTitle = this.newres.data[1].childrenContent;
            this.tab = this.newres.data;
            this.end = this.navigation.data[4].childMenu;
            this.work = this.tab[0].childrenContent
            this.work.forEach(el => {
                el.keyValue = el.keyValue?JSON.parse(el.keyValue):{}
            })
        })


    },
    watch: {
        '$route'(val, from) {
            console.log(val, from)
            location.reload()
        }
    },
    methods: {
        go(url) {
            let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
            let path = url.split('?')[0]
            let paramter = url.split('=')[1]
            if (rule.test(url)) {
                window.open(url, '_blank')
                this.isDown = false;
            } else {
                this.$router.push({ path: `${path}`, query: { datekey: paramter } });
                this.isDown = false;
            }
        },
        handleChangeDepartment(item,index){
            console.log(item,index)
            this.activeDepart = index
            this.work = JSON.parse(JSON.stringify(item.childrenContent))
        },
        handleClick(item){
            this.$router.push({
                path: '/jobdetail',
                query:{
                    id: item.id
                }
            })
        },
    },
}
</script>

<style scoped>
.contain {
    width: 1440px;
    margin: 0 auto;
}

.clear {
    zoom: 1;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.bread {
    margin-right: 25px;
    padding-left: 30px;
    /* background: url(../images/bread.png) no-repeat left; */
}

.bread a {
    display: inline-block;
    color: #fff;
}

.bread span {
    display: inline-block;
    color: #fff;
}

.inside_banner {
    height: 600px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.inside_banner .font {
    height: 100%;
    color: #fff;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.inside_banner .font .p1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.inside_banner .font .p2 {
    font-size: 36px;
    font-weight: bold;
}


.inside_banner .nav a.active:before,
.inside_banner .nav a:hover:before {
    width: 100%;
    left: 0;
}

.inside_banner .nav a {
    float: left;
    color: #fff;
    font-size: 18px;
    margin: 0 40px;
    position: relative;
}





.join .top {
    padding-bottom: 40px;
    overflow: auto;
}

.join .top .main li {
    width: 454px;
    float: left;
    margin-bottom: 40px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 10px 20px 10px rgba(230, 235, 242, 1);
    margin-right: 39px;
}

.join .top .main li:nth-of-type(3n) {
    margin-right: 0;
}

.join .top .main li .font {
    padding: 30px 40px;
}

.join .end {
    background: #f5f6fa;
    font-size: 0px;
    text-align: center;
}

.join .end .tab {
    margin-bottom: 35px;
    display: inline-block;
    margin: 10px;
}

.join .end .tab a {
    display: inline-block;
    width: 200px;
    padding: 4px;
    font-size: 20px;
    background: #fff;
    color: #1f2b41;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 12px;
}

.join .end .tab a:active,
.join .end .tab a:hover {
    background-image: linear-gradient(#fff, #fff), linear-gradient(to right, #61d8ff, #00a7eb);
    background-clip: content-box, padding-box;
}
.join .end .active,
.join .end .tab a:hover {
    background-image: linear-gradient(#fff, #fff), linear-gradient(to right, #61d8ff, #00a7eb);
    background-clip: content-box, padding-box;
}

.join .end .tab a p {
    padding-bottom: 45px;
}

.join .end .tab a:last-child {
    margin-right: 0;
}
.join .end .tabs{
    display: flex;
    justify-content: center;
}
.join .end .tab a img {
    width: 60px;
    height: 54px;
    transition: 0.3s all;
    margin: 35px auto 30px;
    display: block;
}
.join .end .main {
    display: flex;
    flex-wrap: wrap;
}
.join .end .main li {
    width: 33%;
    box-sizing: border-box;
    padding: 20px;
    float: left;
    margin-bottom: 30px;
    border-radius: 6px;
}
.join .end .main li .job-item{
    position: relative;
    height: 220px;
    background-color: #fff;
}
.join .end .main li a {
    padding: 20px;
}
.join .end .main li .p3{
    margin-bottom: 0;
    margin-top: 0;
}
.join .end .main li:nth-of-type(2n) {
    float: right;
}


.about_title {
    text-align: center;
    padding: 60px 0;
}

.about_title .pw {
    color: #848a96;
}
.recruiting-content{
    background-color: #fff;
    width: 1150px;
    padding: 30px;
    margin: 30px auto 0;
    font-size: 14px;
    text-align: left;
}
</style>

