<template>
  <div class="main">
    <div class="topmain">
      <div class="l">
        <div class="title1">{{ titleSubtitle }}</div>
        <div class="title2">{{ title }}</div>
        <div class="article">
          {{ describeInfo }}
        </div>
      </div>
      <div class="r" ref="pic1"></div>
    </div>
    <div class="toparticle">
      <div class="w">
        {{ describeInfo2 }}
      </div>
    </div>
    <div class="maindata w" id="maindata">
      <div class="title">{{ title2 }}</div>
      <div class="datas">
        <div class="data" v-for="(i, index) in numList" :key="index">
          <div class="datanum">
            <animate-number
              from="0"
              :to="i.title"
            ></animate-number>
            {{ i.titleSubtitle }}
          </div>
          <div class="datatext">{{ i.describeInfo }}</div>
        </div>
      </div>
    </div>
    <div class="work">
      <div class="w">
        <div class="title">{{ title3 }}</div>
        <div class="workdata">
          <div class="l">
            <div class="sentence" v-html="detailInfo"></div>
          </div>
          <div class="r" ref="pic2"></div>
        </div>
      </div>
    </div>
    <div class="report">
      <div class="w">
        <div class="title">{{ title4 }}</div>
        <div class="workdata">
          <div class="r" ref="pic3"></div>
          <div class="l">
            <div class="sentence" v-html="detailInfo2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      title: "",
      title2: "",
      title3: "",
      title4: "",
      titleSubtitle: "",
      describeInfo: "",
      describeInfo2: "",
      describeInfo3: "",
      detailInfo: "",
      detailInfo2: "",
      numList: [],
      newres:[],
      w: window.pageYOffset,
      flag: true,
    };
  },
      watch: {
        '$route'(val,from){
           console.log(val,from)
           location.reload()
        }
    },
  // watch: {
  //   w: {
  //     immediate: true,
  //     handler(w) {
  //       this.$nextTick(() => {
  //         if (w > document.getElementById("maindata").offsetTop - 500&&this.flag) {
  //           this.numList.map((i, index) => {
  //             this.$refs["myNum" + index][0].start();
  //           });
  //           this.flag=false
  //         }
  //       });
  //     },
  //   },
  // },
  mounted() {
    window.addEventListener("scroll", () => {
      this.w = window.pageYOffset;
    });
    this.$store.state.data.then((res) => {
      res.data.data.map((el)=>{
            if(el.mouldSectionKey==this.$route.query.datekey){
              this.newres=el
              // console.log(this.newres111111111111111)
            }
      })
      this.title = this.newres.data[0].childrenContent[0].title;
      this.titleSubtitle = this.newres.data[0].childrenContent[0].titleSubtitle;
      this.describeInfo = this.newres.data[0].childrenContent[0].describeInfo;
      this.$refs.pic1.style.backgroundImage = `url('${this.newres.data[0].childrenContent[0].picUrl}')`;
      this.$refs.pic1.style.backgroundSize = `cover`;
      this.describeInfo2 = this.newres.data[1].childrenContent[0].describeInfo;
      this.title2 = this.newres.data[2].childrenContent[0].title;
      this.newres.data[2].childrenContent.map((i, index) => {
        if (index > 0) {
          this.numList.push({
            title: i.title * 1,
            titleSubtitle: i.titleSubtitle,
            describeInfo: i.describeInfo,
          });
        }
      });
      this.title3 = this.newres.data[3].childrenContent[0].title;
      this.detailInfo = this.newres.data[3].childrenContent[0].detailInfo;
      this.$refs.pic2.style.backgroundImage = `url('${this.newres.data[3].childrenContent[0].picUrl}')`;
      this.$refs.pic2.style.backgroundSize = `cover`;
      this.title4 = this.newres.data[4].childrenContent[0].title;
      this.detailInfo2 = this.newres.data[4].childrenContent[0].detailInfo;
      this.$refs.pic3.style.backgroundImage = `url('${this.newres.data[4].childrenContent[0].picUrl}')`;
      this.$refs.pic3.style.backgroundSize = `cover`;
      // console.log(this.newres .data);
    });
  },
};
</script>

<style scoped>
@media screen and (max-width: 750px){
  .topmain{
    padding: .641rem;
  }
  .topmain .title1{
    font-size: .359rem;
    margin-top: .2564rem;
  }
  .topmain .title2{
    margin-top: .2564rem;
    font-size: .9231rem;
    font-weight: 700;
  }
  .topmain .article{
    font-size: .359rem;
    margin-top: .2564rem;
  }
  .report .title{
    font-size: .9744rem;
    font-weight: 700;
  }
  .report .w{
    width: 100%;
    margin: 0;
  }
  .report{
    padding: .641rem;
  }
  .report .r{
    width: 100%;
    height: 7.6923rem;
    margin-top: .2564rem;
    background-position: center center;
    background-size: 100% auto;
  }
  .report .sentence{
    font-size: .4103rem;
    margin-top: .2564rem;
  }
  .work .title{
    font-size: .9744rem;
    font-weight: 700;
  }
  .work .w{
    width: 100%;
    margin: 0;
  }
  .work .r{
    width: 100%;
    height: 7.6923rem;
    margin-top: .2564rem;
    background-position: center center;
    background-size: 100% auto;
  }
  .work .sentence{
    font-size: .4103rem;
  }
  .topmain .r{
    width: 100%;
    margin-top: .2564rem;
    height: 5.1282rem;;
  }
  #maindata .title{
    font-size: .9231rem;
    text-align: center;
    font-weight: 700;
  }
  .work{
    padding: .641rem;
    background-color: rgb(242, 242, 242)
  }
  .datas{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: .2564rem;
    font-size: .7692rem;
  }
  .data{
    display: inline-block;
    margin-left: .3846rem;
    margin-bottom: .3846rem;
    font-weight: 700;
  }
}
@media screen and (min-width: 750px){
  .main {
  margin-top: 0.45rem;
  overflow: hidden;
}
.topmain {
  min-height: 2.32rem;
  overflow: hidden;
}
.topmain .r {
  float: left;
  width: 50%;
  min-height: 2.32rem;
}
.topmain .l {
  float: left;
  width: 35%;
  margin-left: 13%;
  margin-right: 2%;
}
.topmain .l .title1 {
  margin-top: 0.5rem;
  margin-bottom: 0.05rem;
  font-weight: bold;
  font-size: 0.1rem;
  color: #004dff;
}
.topmain .l .title2 {
  font-size: 0.4rem;
  font-weight: bold;
}
.topmain .l .article {
  font-size: 0.105rem;
  line-height: 0.17rem;
  margin-top: 0.07rem;
  margin-bottom: 0.2rem;
}
.toparticle {
  background-color: rgb(242, 242, 242);
  overflow: hidden;
}
.toparticle .w {
  margin-top: 0.34rem;
  margin-bottom: 0.34rem;
  padding-left: 0.2rem;
  padding-right: 0.5rem;
  font-size: 0.2rem;
  border-left: 0.03rem solid #a100ff;
}
.maindata .title {
  font-size: 0.28rem;
  font-weight: bold;
  margin-top: 0.25rem;
  margin-bottom: 0.14rem;
}
.maindata .datas {
  overflow: hidden;
  margin-bottom: 0.2rem;
}
.maindata .datas .data {
  float: left;
  width: calc(94% / 3);
  margin-right: 3%;
}
.maindata .datas .data:nth-child(3n) {
  margin-right: 0;
}
.maindata .datas .data .datanum {
  font-size: 0.4rem;
  font-weight: bold;
  border-bottom: 0.03rem solid #a100ff;
}
.maindata .datas .data .datatext {
  font-size: 0.08rem;
  margin-top: 0.08rem;
  margin-bottom: 0.1rem;
}
.work {
  background-color: rgb(242, 242, 242);
  overflow: hidden;
  padding-bottom: 0.3rem;
}
.work .title {
  font-size: 0.28rem;
  font-weight: bold;
  margin-top: 0.25rem;
  margin-bottom: 0.2rem;
}
.work .workdata {
  overflow: hidden;
  min-height: 2rem;
}
.work .workdata .l {
  width: 48%;
  float: left;
}
.work .workdata .l .sentence {
  font-size: 0.1rem;
  margin-bottom: 0.2rem;
  line-height: 0.155rem;
}
.work .workdata .r {
  width: 48.5%;
  margin-left: 3.5%;
  height: 2.35rem;
  float: left;
}
.report {
  overflow: hidden;
  padding-bottom: 0.3rem;
}
.report .title {
  font-size: 0.28rem;
  font-weight: bold;
  margin-top: 0.25rem;
  margin-bottom: 0.2rem;
}
.report .workdata {
  overflow: hidden;
  min-height: 2rem;
}
.report .workdata .l {
  width: 48%;
  float: left;
}
.report .workdata .l .sentence {
  font-size: 0.1rem;
  margin-bottom: 0.2rem;
  line-height: 0.155rem;
}
.report .workdata .r {
  width: 48.5%;
  margin-right: 3.5%;
  height: 2rem;
  float: left;
}
}
</style>