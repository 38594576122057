<template>
    <div>
        <div class="inside_banner" :style="{ 'background-image': 'url(' + top_bg + ')' }">
            <div class="font">
                <div class="contain">
                    <p class="p1">品牌新闻</p>
                    <p class="p2">BRAND NEWS</p>
                </div>
            </div>
            <div class="end">
                <div class="contain clear">
                    <div class="nav fl" v-for="i in end" @click="go(i.url)">
                        <a>{{ i.name }}</a>
                    </div>
                    <div class="bread fr">
                        <span>位置：</span>
                        <a href="">首页</a>
                        <span>-</span>
                        <a>品牌新闻</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="news_detail">
            <div class="contain clear">
                <div class="left fl">
                    <div class="title">
                        <p class="p1">{{ JournaTitle }}</p>
                        <p class="p2">
                            <span>
                                <img  :src="ReleaseTime_bg.picUrl" alt="发布时间">
                                <em>发布时间：</em>
                                {{ JournaTime }}</span>
                            <span>
                                <img :src="author_bg.picUrl" alt="作者">
                                <em>作者：</em>
                                {{ JournaAuthor }} </span>
                        </p>
                    </div>
                    <div class="simple-article">
                        <p><span style="font-family: 宋体; font-size: 16px;">
                                <p v-html="test"></p>
                            </span>
                        </p>
                        <p><img loading="lazy" class="size-full wp-image-399 aligncenter" :src="top_bg" alt="" width="1405"
                                height="786" srcset="" sizes="(max-width: 1405px) 100vw, 1405px"></p>

                        <p>&nbsp;</p>
                    </div>
                    <div class="share">
                        <div class="social-share share-component" data-sites="weibo,wechat,qq">
                            <a class="social-share-icon  icon-weibo" href="" target="_blank">
                            </a>
                            <a class="social-share-icon icon-wechat" href="javascript:" >
                                <div class="wechat-qrcode">
                                    <h4>微信扫一扫：分享</h4>
                                    <div class="qrcode" title="">
                                        <canvas width="100" height="100" style="display: none;">
                                        </canvas>
                                        <img alt="Scan me!" src="" style="display: block;">
                                    </div>
                                    <div class="help">
                                        <p>微信里点“发现”，扫一下</p>
                                        <p>二维码便可将本文分享至朋友圈。</p>
                                    </div>
                                </div>
                            </a>
                            <a class="social-share-icon icon-qq" href="" target="_blank">
                            </a>
                        </div>
                    </div>
                    <div class="np">
                        <div>
                            <a href="" title="【飞闻】多飞科技成功加入江苏省信息技术应用创新联盟">
                                <em>上一篇：</em>【飞闻】多飞科技成功加入江苏省信息技术应用创新联盟
                            </a>
                        </div>
                        <div>
                            <a href="" title="捷报！多飞科技成功荣获江苏省“创新型中小企业”认定">
                                <em>下一篇：</em>捷报！多飞科技成功荣获江苏省“创新型中小企业”认定
                            </a>
                        </div>
                    </div>
                </div>
                <div class="right fr">
                    <p class="p1"><span>最新新闻</span></p>
                    <ul class="main">
                        <li v-for="i in brandNewsList.slice(0, 4)">
                            <div @click="go(i.linkUrl)">
                                <a class="img">
                                    <img :src="i.picUrl">
                                </a>
                                <a href="" class="a1">{{ i.title }}</a>
                                <p class="p2">2023-01-16</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'News2',
    components: {

    },
    data() {
        return {
            newres: {},
            navigation: {},
            end: [],
            top_bg: '',
            ReleaseTime_bg: '',
            author_bg: '',
            data: {},
            Journalism: {},
            test: '',
            JournaTitle: "",
            JournaTime: "",
            JournaAuthor: "",
            brandNews: {},
            brandNewsList: []
        };
    },
    mounted() {
        this.$store.state.data.then((res) => {
            console.log(res)
            res.data.data.map((el) => {
                if (el.mouldSectionKey == "0bffc2ec-bc59-4a1f-ae4a-fb472ed833ab") {
                    this.newres = el
                }
                //导航
                if (el.mouldSectionKey == "b63a8bd6-21d5-4934-afd2-c0e1fdd04cea") {
                    this.navigation = el
                }
                //新闻
                if (el.mouldSectionKey == "d7d47710-3848-49cb-8b1f-70b861fe65c9") {
                    this.Journalism = el
                }
                //公司动态
                if (el.mouldSectionKey == "20f2c691-7611-470c-9d32-97c6d22faa0d") {
                    this.brandNews = el
                }
            })
            this.top_bg = this.newres.data[0].picUrl;
            this.data = this.newres.data[1].childrenContent;
            this.end = this.navigation.data[4].childMenu;
            //新闻数据接收
            this.ReleaseTime_bg = this.Journalism.data[0].childrenContent[0];
            this.author_bg = this.Journalism.data[0].childrenContent[1];
            this.test = this.Journalism.data[1].childrenContent[0].detailInfo;
            this.JournaTitle = this.Journalism.data[1].childrenContent[0].title;
            const str = this.Journalism.data[1].childrenContent[0].titleSubtitle;
            const dataArry = str.split("/");
            this.JournaTime = dataArry[0];
            this.JournaAuthor = dataArry[1];
            //公司动态数据接收
            this.brandNewsList = this.brandNews.data[1].childrenContent;

        })
    },
    watch: {
        '$route'(val, from) {
            console.log(val, from)
            location.reload()
        }
    },
    methods: {
        go(url) {
            let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
            let path = url.split('?')[0]
            let paramter = url.split('=')[1]
            if (rule.test(url)) {
                window.open(url, '_blank')
                this.isDown = false;
            } else {
                this.$router.push({ path: `${path}`, query: { datekey: paramter } });
                this.isDown = false;
            }
        },
    },
}
</script>


<style scoped>
img {
    max-width: 100%;
    height: auto;
    border: none;
    vertical-align: top;
}

a {
    text-decoration: none;
    color: inherit;
    display: block;
    transition: all 0.3s;
}

li {}

.contain {
    width: 1440px;
    margin: 0 auto;
}

.clear {
    zoom: 1;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.bread {
    margin-right: 25px;
    padding-left: 30px;
    /* background: url(../images/bread.png) no-repeat left; */
}

.bread a {
    display: inline-block;
    color: #fff;
}

.bread span {
    display: inline-block;
    color: #fff;
}

.inside_banner {
    height: 600px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.inside_banner .font {
    height: 100%;
    color: #fff;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.inside_banner .font .p1 {
    font-size: 32px;
    margin-bottom: 10px;
}

.inside_banner .font .p2 {
    font-size: 36px;
    font-weight: bold;
    color: #e5e5e5;
}


.inside_banner .nav a.active:before,
.inside_banner .nav a:hover:before {
    width: 100%;
    left: 0;
}

.inside_banner .nav a {
    float: left;
    color: #fff;
    font-size: 18px;
    margin: 0 40px;
    position: relative;
}





.news_detail {
    padding-top: 60px;
    padding-bottom: 90px;
    overflow: auto;
}

.news_detail .left {
    width: 1000px;
}

.news_detail .left .title {
    padding-bottom: 35px;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 50px;
}

.news_detail .left .title .p1 {
    line-height: 50px;
    font-size: 32px;
    color: #666666;
    margin-bottom: 35px;
}

.news_detail .left .title .p2 {
    line-height: 28px;
}

.news_detail .left .title .p2 span {
    margin-right: 50px;
    padding-left: 25px;
}
.news_detail >.left >.title >.p2 >span >img {
    background-color: #e5e5e5;
}

.news_detail .left .simple-article {
    color: #666666;
    font-size: 16px;
    line-height: 32px;
}

.news_detail .left .np {
    font-size: 16px;
    line-height: 32px;
    border-top: 1px solid #e5e5e5;
    padding-top: 40px;
}

.news_detail .left .np a {
    color: #333;
}

.news_detail .left .np a em {
    color: #777;
}

.news_detail .right {
    width: 300px;
}

.news_detail .right .p1 {
    margin-bottom: 10px;
    border-top: 1px solid #dcdcdc;
}

.news_detail .right .p1 span {
    font-size: 16px;
    color: #333;
    padding-top: 15px;
    border-top: 3px solid #00a7eb;
}

.news_detail .right li {
    padding-top: 20px;
}

.news_detail .right li .p2 {
    color: #777;
    margin-top: 15px;
}

.news_detail .right li .img {
    overflow: hidden;
    border-radius: 4px;
}

.news_detail .right li .img img {
    transition: 0.3s all;
}

.news_detail .right li:hover .img img {
    transform: scale(1.1);
}

.news_detail .right li:hover .a1 {
    color: #00a7eb;
}

.share {
    padding-top: 50px;
    padding-bottom: 40px;
}

.share .social-share {
    text-align: center;
}

.social-share {
    font-family: "socialshare" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}

.social-share .icon-wechat .wechat-qrcode {
    display: none;
    border: 1px solid #eee;
    position: absolute;
    z-index: 9;
    top: -205px;
    left: -84px;
    width: 200px;
    height: 192px;
    color: #666;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 10px #aaa;
    transition: all 200ms;
    -webkit-tansition: all 350ms;
    -moz-transition: all 350ms;
}

.share .social-share .social-share-icon {
    border: 0;
    margin: 0 12px;
    width: 48px;
    display: inline-block;
    float: none;
    height: 48px;
}

.share .social-share .social-share-icon:before {
    display: none;
}

.share .social-share .icon-weibo {
    background: url(../../../assets/images/share1.png) no-repeat center;
}

.share .social-share .icon-wechat {
    background: url(../../../assets/images/share2.png) no-repeat center;
}

.share .social-share .icon-qq {
    background: url(../../../assets/images/share3.png) no-repeat center;
}
</style>

