import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import VueRouter from "vue-router";
import "element-ui/lib/theme-chalk/index.css";
import animateNumber from 'vue-animate-number'
import router from "./router";
import "./utils/flex";
// import 'swiper/dist/css/swiper.min.css'
// import 'swiper/dist/js/swiper.min'
import store from "./store";
// import 'swiper/css';
import Swiper from 'swiper';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import './assets/css/website.css'
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);
Vue.use(VueRouter);
//Vue.use(Swiper);
Vue.use(animateNumber);
Vue.config.productionTip = false;
new Vue({
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  router,
  store,
  mounted(){
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");
