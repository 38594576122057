<template>
    <div>car</div>
</template>

<script>
export default {
  name: "car",
  data() {
    return {};
  },
  mounted() {},

}
</script>

<style>

</style>
