<template>
  <div>
    <div class="inside_banner" :style="{ 'background-image': 'url(' + topBg + ')' }">
      <div class="font">
        <div class="contain">
          <p class="p1">{{title}}</p>
          <p class="p2">{{titleSubtitle}}</p>
        </div>
      </div>
      <div class="end">
        <div class="contain clear">
          <div class="nav fl" :class="activeKey == index && 'nav-active'" v-for="(i,index) in productNameArr" :key="index">
            <a @click="go(i.url)">{{ i.name }}</a>
          </div>
          <div class="bread fr">
            <span>位置：</span>
            <a href="">首页</a>
            <span>-</span>
            <a>{{title}}</a>
            <span>-</span>
            <a>{{stitle}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="product">
      <!-- <div class="tab" :class="isFixed&&'fixed'" ref="tabRef">
        <a v-for="(item,index) in productDetailArr" :key="index" @click="handleTab('#part'+(index+1))" href="javascript:;" :class="activeTabIndex == '#part'+(index+1) && 'active'">{{item.title}}</a>
      </div> -->
      <div class="">

        <div v-for="(item,index) in productDetailArr" class="part" :key="index" :id="'part'+(index+1)" :style="item.picUrl&&'background:url('+item.picUrl+')'">
          <div class="contain">
            <div class="title">
              <p class="p1">{{ productDetailArr[index].title }}</p>
            </div>
            <div class="p2" v-html="productDetailArr[index].detailInfo"></div>
            <div class="img">
              <!-- <img src=""> -->
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "vue-awesome-swiper"
import "swiper/css/swiper.css"
import mixins from '@/mixins'
export default {
  name: 'AboutUs',
  components: {
    Swiper,
    SwiperSlide
  },
  mixins:[mixins],
  data() {
    return {
      topBg: "",
      coreProductTitle: null,
      coreProductSubTitle: null,
      productNameArr:[],
      productDetailArr:[{},{},{},{}],
      newres: {},
      example: {},
      honor: {},

      navigation: {},
      end: [],
      test: "",
      part1: {},
      part2: {},
      corporateCulture: {},
      arr: [],
      index: '',
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 30,
        slidesPerGroup: 5,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      activeKey: 0,
      title: '',
      stitle: '',
      titleSubtitle: ''
    };
  },
  mounted() {
    this.init()
    this.isActive()
  },
  watch: {
    '$route'(val, from) {
      this.init()
      this.isActive()
    }
  },
  methods: {
    on_bot_enter() {
      this.$refs.mySwiper.$swiper.autoplay.stop()
    },
    on_bot_leave() {
      this.$refs.mySwiper.$swiper.autoplay.start()
    },
    go(url) {
      let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
      let path = url.split('?')[0]
      let paramter = url.split('=')[1]
      if (rule.test(url)) {
        window.open(url, '_blank')
        this.isDown = false;
      } else {
        this.$router.push({path: `${path}`, query: {datekey: paramter}});
        this.isDown = false;
      }
    },
    init(){
      let that = this
      let dateKey = decodeURIComponent(that.$route.query.datekey)
      if (!dateKey) {
        dateKey = '9ab5574d-bd18-49d7-b5f1-c6b7b55e90b9'
      }
      that.$store.state.data.then((res) => {
        res.data.data.map((el) => {
          if (el.mouldSectionKey == dateKey) {
            that.newres = el
          }
          if(el.mouldSectionKey == '9ab5574d-bd18-49d7-b5f1-c6b7b55e90b9'){
            that.title = el.data[0].childrenContent[0].title
            that.titleSubtitle = el.data[0].childrenContent[0].titleSubtitle
          }
            //导航
          if (el.mouldSectionKey == "b63a8bd6-21d5-4934-afd2-c0e1fdd04cea") {
            this.navigation = el
          }
        })
        that.topBg =  that.newres.data[0].picUrl
        that.productNameArr = this.navigation.data[1].childMenu
        that.stitle = that.newres.name
        that.productDetailArr = that.newres.data[1].childrenContent
      })
    },
    isActive(){
      if(window.location.href.indexOf('d6041f19-7f41-4799-9980-4ed627905890') > -1){
        this.activeKey = 0
      }else if(window.location.href.indexOf('c6dd3333-3455-426a-a733-36748e4a3d10') > -1){
        this.activeKey = 1
      }else if(window.location.href.indexOf('64abe8e3-9598-499c-8fbe-d4ce6a15a910') > -1){
        this.activeKey = 2
      }else if(window.location.href.indexOf('44b0d35f-7f61-4092-a54b-34f96d6d6302') > -1){
        this.activeKey = 3
      }
    }
    // subStr(str){
    //     const startIndex = 'key":"';
    //     const endIndex ='",';
    //      this.index = str.index(startIndex,endIndex);
    //     return this.index;
    // }
  },
}
</script>


<style scoped>

img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: top;
}

a {
  text-decoration: none;
  color: inherit;
  display: block;
  transition: all 0.3s;
}

.contain {
  width: 1440px;
  margin: 0 auto;
}

.clear {
  zoom: 1;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.bread {
  margin-right: 25px;
  padding-left: 30px;
  /* background: url(../images/bread.png) no-repeat left; */
}

.bread a {
  display: inline-block;
  color: #fff;
}

.bread span {
  display: inline-block;
  color: #fff;
}

.inside_banner {
  height: 600px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.inside_banner .font {
  height: 100%;
  color: #fff;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.inside_banner .font .p1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.inside_banner .font .p2 {
  font-size: 36px;
  font-weight: bold;
}
.inside_banner .nav {
  cursor: pointer;
}


.inside_banner .nav a.active:before,
.inside_banner .nav-active a:before,
.inside_banner .nav a:hover:before {
  width: 100%;
  left: 0;
}

.inside_banner .nav a {
  float: left;
  color: #fff;
  font-size: 18px;
  margin: 0 40px;
  position: relative;
  text-decoration: none;
}

.inside_banner .nav a.active,
.inside_banner .nav a:hover {
  color: #00a7eb;
}




.scrollBar {
  padding-right: 20px;
}

.scrollBar::-webkit-scrollbar-track-piece {
  background-color: #ececec;
}

.scrollBar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scrollBar::-webkit-scrollbar-thumb {
  background-color: #00a7eb;
  background-clip: padding-box;
  min-height: 28px;
}

.product .title {
    text-align: center;
}

.product .title .p1 {
    font-size: 28px;
    color: #666666;
}


.product .part .p2{
  color: #666666;

}
.product .part .p2 >>> span{
  line-height: 32px;
}
.product .tab {
  background: #fbfcff;
  text-align: center;
}
.product .part{
  background-size: contain !important;
}
.product .part .contain{
  width: 1200px ;
}
</style>

