<template>
  <div class="brandnews">
    <div class="inside_banner"
         :style="{'background-image':'url('+top_bg+')'}">
      <div class="font">
        <div class="contain">
          <p class="p1">{{title}}</p>
          <p class="p2">{{titleSubtitle}}</p>
        </div>
      </div>
      <div class="end">
        <div class="contain clear">
          <div class="nav fl" v-for="i in end " @click="go(i)" :class="i.url.indexOf('brandNews') > -1 && 'nav-active'">
            <a>{{ i.name }}</a>
          </div>
          <div class="bread fr">
            <span>位置：</span>
            <a href="">首页</a>
            <span>-</span>
            <a>{{title}}</a>
            <span>-</span>
            <a>{{stitle}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="news">
      <ul class="main clear contain">
        <li v-for="i in data" @click="go(i)">
          <div>
            <div class="img">
              <img width="450" height="155" :src="i.picUrl" class="attachment-450x270 size-450x270" alt="" loading="lazy">
            </div>
            <div class="font">
              <div class="date fl">
                <p class="p1">{{i.date1}}</p>
                <p class="p2">{{i.date2}}</p>
              </div>
              <a href="javascript:;" class="a1">
                {{ i.title }}</a>
            </div>
          </div>
        </li>
      </ul>
      <ul class="paging">
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BrandNews',
  components: {},
  data() {
    return {
      newres: {},
      navigation: {},
      end: [],
      top_bg: '',
      data: {},
      title: '',
      stitle: '',
      titleSubtitle: ''
    };
  },
  mounted() {
    this.$store.state.data.then((res) => {
      res.data.data.map((el) => {
        //公司动态
        if (el.mouldSectionKey == "20f2c691-7611-470c-9d32-97c6d22faa0d") {
          this.newres = el
          this.title = el.data[0].childrenContent[0].title
          this.titleSubtitle = el.data[0].childrenContent[0].titleSubtitle
          this.stitle = el.name
        }
        //导航
        if (el.mouldSectionKey == "b63a8bd6-21d5-4934-afd2-c0e1fdd04cea") {
          this.navigation = el
        }
      })
      this.top_bg = this.newres.data[0].picUrl;
      this.data = this.newres.data[1].childrenContent.map(el => {
        let picUrl = el?.picUrl
        if(el?.picUrl?.indexOf(',') > -1){
          picUrl = el?.picUrl?.split(',')[0]
        }
        const date = el.describeInfo?.split(',')[0]
        return {
          ...el,
          picUrl,
          date1: date?.slice(0,4),
          date2: date?.slice(5,10)
        }
      })
      this.end = this.navigation.data[3].childMenu;
    })
  },
  watch: {
    '$route'(val, from) {
      console.log(val, from)
      location.reload()
    }
  },
  methods: {
    go(item) {
      const url = item.linkUrl || item.url
      let rule = /^(http|https|ftp|file|rtmp|rtsp):\/\//
      let path = url.split('?')[0]
      let paramter = url.split('=')[1]
      if (rule.test(url)) {
        window.open(url, '_blank')
        this.isDown = false;
      } else {
        this.$router.push({path: `${path}`, query: {id:item.id,type:'brandnews',datekey: this.$route.query.datekey|| ''}});
        this.isDown = false;
      }
    },
  },
}
</script>


<style scoped>

img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: top;
}

a {
  text-decoration: none;
  color: inherit;
  display: block;
  transition: all 0.3s;
}

.contain {
  width: 1440px;
  margin: 0 auto;
}

.clear {
  zoom: 1;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.bread {
  margin-right: 25px;
  padding-left: 30px;
  /* background: url(../images/bread.png) no-repeat left; */
}

.bread a {
  display: inline-block;
  color: #fff;
}

.bread span {
  display: inline-block;
  color: #fff;
}

.inside_banner {
  height: 600px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.inside_banner .font {
  height: 100%;
  color: #fff;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.inside_banner .font .p1 {
  font-size: 32px;
  margin-bottom: 10px;
}

.inside_banner .font .p2 {
  font-size: 36px;
  font-weight: bold;
}

 
.inside_banner .nav {
}
.inside_banner .nav a.active:before,
.inside_banner .nav a:hover:before {
  width: 100%;
  left: 0;
}

.inside_banner .nav a {
  float: left;
  color: #fff;
  font-size: 18px;
  margin: 0 40px;
  position: relative;
}

.inside_banner .nav a.active,
.inside_banner .nav a:hover {
  color: #00a7eb;
}



.news .main {
  overflow: auto;
}

.news .main li {
  margin-top: 55px;
  width: 450px;
  float: left;
  margin-right: 45px;
}

.news .main li:nth-of-type(3n) {
  margin-right: 0;
}

.news .main li .img {
  width: 100%;
  height: 270px;
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.news .main li .font {
  padding-top: 25px;
}

.news .main li .font .date {
  width: 85px;
  text-align: center;
  margin-right: 18px;
  border-right: 1px solid #d5d9df;
}


.news .main li .img img {
  transition: 0.3s all;
}

.news .main li:hover .img img {
  transform: scale(1.1);
}

.news .main li:hover .font .a1 {
  color: #00a7eb;
}

.paging {
  text-align: center;
  padding: 50px 0 75px;
}
.brandnews .news .main{
  display: flex;
  flex-wrap: wrap;
}
.brandnews .news .main li{
  width: 33%;
  margin: 0;
  box-sizing: border-box;
  padding: 20px;
}
</style>

