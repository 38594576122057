<template>
  <div>About</div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped>
</style>