<template>
    <!--数据运营-->
    <div class="contain">
        <div style="height: 150px;"></div>

        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="(item, index) in data" :key="index" :label="item.title">
                <div class="top">
                    <p class="p1">{{ item.title }}</p>
                    <p class="p2">{{ item.titleSubtitle }}</p>
                </div>
                <div class="simple-article">
                    <p style="font-size: 20px;" v-html="item.detailInfo">
                    </p>
                    <p><img class="size-full wp-image-343 aligncenter" :src="item.picUrl" alt="" width="1305" height="1251">
                    </p>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    name: 'one',
    components: {

    },
    data() {
        return {
            newres: [],
            data: []
        }
    },
    mounted() {
        this.$store.state.data.then((res) => {
            console.log(res)
            res.data.data.map((el) => {
                //数智运营
                if (el.mouldSectionKey == "05273ada-55cd-4fe5-b122-bd5a783e9f64") {
                    this.newres = el
                }
            })
            this.data = this.newres.data[1].childrenContent;
        })

    },

}
</script>
<style>
.el-tabs__header {
    font-size: 20px;
    font-weight: bold;
    color: #1f2b41;
    margin-right: 50px;
    padding-bottom: 25px;
    line-height: 30px;
}

</style><style scoped>
a {
    text-decoration: none;
    color: inherit;
    display: block;
    transition: all 0.3s;
}

.clear {
    zoom: 1;
}

.contain {
    width: 1440px;
    margin: 0 auto;
}

.contain .tab {
    margin-bottom: 80px;
    border-bottom: 1px solid #bcc5dd;
}

.contain .tab a {
    font-size: 20px;
    font-weight: bold;
    color: #1f2b41;
    margin-right: 50px;
    padding-bottom: 25px;
    line-height: 30px;
    float: left;
    position: relative;
}

.contain.tab a:before {
    content: "";
    position: absolute;
    left: 50%;
    width: 0;
    transition: 0.3s all;
    height: 4px;
    background: #00a7eb;
    bottom: 0;
}

.contain .tab a.active,
.contain .tab a:hover {
    color: #00a7eb;
    font-size: 24px;
}

.contain .tab a.active:before,
.contain .tab a:hover:before {
    width: 38px;
    margin-left: -19px;
}

.contain .top {
    margin-bottom: 70px;
}

.contain .top .p1 {
    font-size: 32px;
    font-weight: bold;
    color: #1f2b41;
    margin-bottom: 10px;
}

.contain .top .p2 {
    color: #1f2b41;
    font-size: 18px;
    font-family: "Arial";
}</style>
